.video {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;

    &__img {
        height: 250px; 
        margin-bottom: 24px;
        overflow: hidden;

        &-wrap {
            height: 100%;
            width: 100%;
            background-position: center;
            background-size: cover;
            transition: transform 0.3s ease;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
    }


    &__text {
        font-size: 14px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: @grey_2;
        transition: color 0.3s ease;
    }

    &__name {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: @black;
        margin-bottom: 4px;
        transition: color 0.3s ease;
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 94px;

        .btn {
            span {
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        background-color: @light-red;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 16px;
        flex-wrap: none;

        .svg-icon {
            use {
                fill: #fff;
            }
        }
    }

    &__item {
        display: block;
        margin: 0 16px;
        width: calc(~"33.3% - 32px");
    }

    &__slider {
        .slick-slide {
            padding: 0 16px;
        }

        .slick-list {
            overflow: hidden;
            margin: 0 -16px;
        }

        .video {
            &__item {
                margin: 0;
                width: 100%;
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .video {
        margin-left: -14px;
        margin-right: -14px;

        &__icon {
            width: 3.5vw;
            height: 3.5vw;
            margin-right: 12px;

            .svg-icon {
                width: 11px;
                height: auto;
            }
        }

        &__btn {
            margin-top: 6vw;
        }

        &__text {
            font-size: 1vw;
        }

        &__name {
            font-size: 1.2vw;
        }

        &__img {
            height: 17vw;
        }

        &__item {
            margin: 0 14px;
            width: calc(~"33.3% - 28px");
        }
    }
}

@media(min-width: 960px) {
    .video {
        &__item {
            &:hover {
                .video {
                    &__img {
                        &-wrap {
                            transform: scale(1.1);
                        }
                    }

                    &__name {
                        color: @red;
                    }

                    &__text {
                        color: @red;
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .video {
        margin: 0 -12px;

        &__img {
            height: 19vw;
            margin-bottom: 12px;
        }

        &__btn {
            margin-top: 40px;

            .btn {
                span {
                    display: none;
                }
            }
        }

        &__icon {
            width: 32px;
            height: 32px;
            margin-right: 8px;
            padding-left: 2px;

            .svg-icon {
                width: 11px;
                height: auto;
            }
        }

        &__item {
            margin: 0 12px;
            width: calc(~"33.3% - 24px");
        }
    }
}

@media(max-width: 961px) {
    .video {
        &--main {
            .video {
               
                &__img {
                    height: 29vw;
                }

                &__item {
                    width: calc(~"50% - 24px");

                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }
}


@media(max-width: 641px) {
    .video {
        &--main {
            display: block;

            .video {

                &__name {
                    margin-bottom: 0;
                }
               
                &__img {
                    height: 42vw;
                }

                &__item {
                    width: calc(~"100% - 24px");
                    margin-bottom: 24px;


                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }
}

@media(max-width: 481px) {
    .video {
        &--main {
            .video {
                &__img {
                    height: 56vw;
                }
            }
        }

        &__btn {
            .btn {
                width: 100%;
                padding: 12px;
            }
        }
    }
}