.menu-top {

    &>ul {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;

        &>li {
            position: relative;
            list-style: none;
            margin-right: 28px;

            &:last-child {
                margin-right: 0;
            }
            
            &>a {
                color: @light;
                font-size: 17px;
                line-height: 20px;
                letter-spacing: -0.02em;
            }
        }
    }

    &__control {
        margin-left: 6px;

        .svg-icon {
            use {
                stroke: #fff;
                fill: transparent;
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        text-decoration: none;
        font-weight: 600;
        font-size: 18px;
        line-height: 140.62%;
        color: #1F2F58;
        transition: color 0.3s ease;

        &>span {
            position: relative;

            &::before {
                content:" ";
                position: absolute;
                left: 0;
                bottom: -1px;
                right: 0;
                left: 0;
                height: 3px;
                background-color: #fff;
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }

       
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .menu-top {
        &>ul {
            &>li {
                margin-right: 1.8vw;
                
                &>a {
                    font-size: 1.2vw;
                }
            }
        }
    }
}


@media(min-width: 960px) {
    .menu-top {
        &>ul {
            &>li {
                &>a {
                    &:hover {
                        color: #fff;
                    }
                }
                
            }
        }
    }
}

@media(max-width: 960px) {
    .menu-top {
        &__control {
            transition: transform 0.3s ease;
        }

        &__item {
            &.is-active {
                .menu-top {
                    &__control {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}