.advantage {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -50px -50px;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: @red;
        width: 98px;
        height: 98px;  
        margin: 0 auto;
        margin-bottom: 32px;  
    }

    &__text {
        padding: 0 5px;
        font-size: 16px;
        line-height: 153%;
        letter-spacing: -0.02em;

    }

    &__name {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        font-family: 'Bitter', serif;
        margin-bottom: 8px;
    }

    &__item {
        width: 33.3%;
        padding: 0 50px;
        margin-bottom: 50px;
        text-align: center;
    }
}

@media(max-width: 960px) {
    .advantage {
        display: block;
        max-width: 396px;
        margin: 0 auto;

        &__name {
            font-size: 18px;
            line-height: 22px;
        }

        &__text {
            font-size: 14px;
            line-height: 153%; 
        }

        &__icon {
            width: 73px;
            height: 73px;
            margin-bottom: 16px;

            img {
                width: 60%;
                height: auto;
            }
        }

        &__item {
            padding: 0;
            width: 100%;
            text-align: center;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}