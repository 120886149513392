.count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 189px;
    margin-bottom: 21px;

    .ui-spinner  {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        border: 0;
    }

    .ui-spinner-button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 32px;
        height: 32px;  
        background: #F2F2F2;
        border-radius: 20px;
        border: 0;
        box-shadow: none;

        &.ui-spinner-up {
            order: 3;
        }

        &.ui-spinner-down {
            order: 1;
        }
    }

    .ui-spinner-input {
        width: 125px;
        border: 0;
        padding: 0;
        margin: 0;
        order: 2;
        color: #0C0C0C;
        font-weight: 500;
        font-family: 'Bitter', serif;
        font-size: 24px;
        line-height: 29px;
        text-align: center;

    }
}

@media(max-width: 1140px) { 
    .count {
        margin: 0 auto;
        margin-top: 12px;
        width: 165px;

        .ui-spinner-input { 
            width: 100px;
        }
    }
}