.social {
    display: flex;

    &__item {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 8px;
        background-color: @light-red;
        transition: background-color 0.3s ease;



        .svg-icon {
            use {
                fill:  #F3F2F2;
            }
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--black {
        .social {
            &__item {
                background-color: @grey_4;
            }
        }
    }

    &--big {
        .social {
            &__item {
                width: 48px;
                height: 48px; 
            }
        }
    }

    &__container {
        padding: 32px;

        .social {
            &__item {
                width: 48px;
                height: 48px;
                margin-right: 8px;
            }
        }
    }

    &__text {
        font-size: 15px;
        line-height: 150%;
        color: @grey;
        margin-bottom: 16px;
    }
}

@media(max-width: 960px) {
    .social {
        &__container {
            padding: 24px 0 0 0;

            .social {
                justify-content: center;

                &__item {
                    margin: 0 4px;
                }
            }
        }

        &__text {
            text-align: center;
            font-size: 13px;
            line-height: 240%;
        }
    }
}