.pairs {
    &__title {
        font-size: 19px;
        line-height: 150%;
        margin-bottom: 16px;
        font-family: 'Galderglynn1884Cd';
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 23px 0;
        border-top: 1px solid #000000;
        color: inherit;
        text-decoration: none;

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 68px;
            height: 70px;
            margin-right: 32px;
            flex: none;
    
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-name {
            font-weight: 600;
            font-size: 22px;
            line-height: 120%; 
            transition: color 0.3s ease;
        }

        &-text {
            font-weight: 300;
            font-size: 15px;
            line-height: 160%;
            transition: color 0.3s ease;
        }
    }

    &__section {
        margin-bottom: 48px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 960px) {
    .pairs {
        &__item {
            &:hover {
                .pairs {
                    &__item {
                        &-name {
                            color: #fff;
                        }

                        &-text {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .pairs {

        &__title {
            font-size: 1.6vw;
            margin-bottom: 1vw;
        }

        &__section {
            margin-bottom: 2vw;
        }

        &__item {
            padding: 1vw 0;
  
            &-name {
                font-size: 1.3vw;
                margin-bottom: 0.5vw;
            }

            &-text {
                font-size: 1.2vw;
            }

            &-icon {
                width: 6vw;
                height: 6vw;
                margin-right: 2vw;
            }
        }

       
    }
}

@media (max-width: 960px) {
    .pairs {

        &__title {
            font-size: 15px;
            margin-bottom: 7px;
        }

        &__section {
            margin-bottom: 25px;
        }

        &__item {
            padding: 9px 0;
  
            &-name {
                font-size: 16px;
                margin-bottom: 4px;
            }

            &-text {
                font-size: 14px;
            }

            &-icon {
                width: 60px;
                height: 60px;
                margin-right: 15px
            }
        }

       
    }
}