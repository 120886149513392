
.step {
    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    &__text {
        margin-bottom: 57px;
    }


    &__item {
        margin-bottom: 98px;
        
        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;  
        letter-spacing: initial;
    }

    &__number {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #FFFFFF;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        flex: none;
        margin-right: 15px;
        background-color:  #42951B;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .step {
        &__number {
            width: 2.5vw;
            height: 2.5vw;
            font-size: 1.5vw;
            margin-right: 0.7vw;
        }

        &__text {
            margin-bottom: 2vw;
        }

        &__item {
            margin-bottom: 7.2vw;
        }

        &__top {
            margin-bottom: 0.7vw;
        }

        &__title {
            font-size: 2.3vw;
            line-height: 1.2;
        }
    }
}

@media(max-width: 1140px) {
    .step {
        &__number {
            width: 32px;
            height: 32px;
            font-size: 19.2px;
            line-height: 23px;
            margin-right: 10px;
        }

        &__item {
            margin-bottom: 64px;
        }

        &__title {
            font-size: 22px;
            line-height: 26px;
        }
    }
}