.tag {
    margin-bottom: 50px;

    &__main {
        display: none;
    }

    &--card {
        margin-top: 48px;
        margin-bottom: 0;
    }

    &--catalog {
        margin-top: -27px;
    }

    &__container {
        height: 77px;
        overflow: hidden;
        transition: height 0.3s ease;
    }

    &__link {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 153%;
        letter-spacing: -0.02em; 
        opacity: 0.5;
        color: @red;
        margin-top: 6px;

        &-control {
            margin-left: 9px;
            margin-bottom: 1px;
        }

        .svg-icon {
            display: block;
            transition: transform 0.3s ease;

            use {
                fill: transparent;
                stroke: #810D10;
            }
        }

        &.is-active {
            .svg-icon {
                transform: rotate(180deg);
            }
    
        }
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -4px 0;
    }

    &__item {
        cursor: pointer;
        display: inline-flex;
        padding: 5px 10px;
        letter-spacing: -0.02em;
        align-items: center;
        text-decoration: none;
        color: inherit;
        border: 0;
        font-size: 14px;
        line-height: 168%;
        transition: background-color 0.3s, color 0.3s ease;
        margin: 0 4px 8px;
        background-color: @warm_bg;

        &.is-active {
            color: #fff;
            background-color: @red;
        }
    }

    &--page {
        margin-top: 60px;

        .tag {
            &__item {
                padding: 6px 16px 7px;
                font-weight: normal;
                font-size: 18px;
                line-height: 150%; 
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .tag {
        &__item {
            font-size: 1.05vw;
            padding: 0.5vw 1.2vw;
            margin: 0 0.4vw 0.8vw;
        }

        &__container {
            height: 7vw;
        }
        
        &--main-catalog {
            margin-top: 0;
        }

        &--page {
            margin-top: 3.5vw;
            margin-bottom: 0;

            .tag {
                &__item {
                    font-size: 1.3vw;
                    padding: 0.5vw 1vw;
                    margin: 0 0.3vw 0.6vw;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .tag {
        &--card {
            margin-top: 35px;
        }

        &--main-catalog {
            margin-top: 0;
        }

        &__item {
            font-size: 14px;
            line-height: 150%;
            padding: 6px 10px;
        }

        &--page {
            margin-top: 24px;
            margin-bottom: 0;

            .tag {
                &__item {
                    font-size: 14px;
                    line-height: 168%;
                    padding: 4px 10px;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .tag {
        &__item {
            &:hover {
                color: #fff;
                background-color: @red;
            }
        }

        
    }
}


@media(max-width: 960px) {
    .tag {


        &--catalog {
            display: flex;
            position: relative;
            margin-bottom: 0;
            flex-grow: 1;
            margin-top: 0;

            .tag {
                &__main {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-left: 12px;
                    flex-grow: 1;
                    border: 1.5px solid #EDE6E6;
                    height: 40px;
                }

                &__control {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: transform 0.3s ease;
                }

                &__link {
                    display: none;
                }

                &__container {
                    display: none;
                }
            }
        }
    }
}

