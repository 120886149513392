.name-block {
    &__type {
        font-weight: 300;
        font-size: 15px;
        line-height: 160%;
        color: #ABABAB;
    }

    &__title {
        margin-top: 4px;
        display: inline-flex;
        padding-bottom: 4px;
        font-size: 20px;
        line-height: 110%;
        text-transform: uppercase;
        color: #1A5DD1;
        border-bottom: #1A5DD1;
        font-family: 'Galderglynn1884Cd' ,sans-serif;
    }

    &__about {
        margin-top: 32px;
        font-weight: 600;
        font-size: 17px;
        line-height: 160%;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .name-block {
        &__about {
            font-size: 1.5vw;
            margin-top: 1.5vw;
        }
    }
}

