.section {
    padding: 116px 0 137px;

    &--pt0 {
        padding-top: 0 !important;
    }

    &--pb0 {
        padding-bottom: 0 !important;
    }

    &--grey {
        background-color: #F2F2F2; 
    }

    &--interes {
        padding-top: 122px;
        padding-bottom: 159px;
        background-color: #F2F2F2; 

        .section {
            &__top {
                margin-bottom: 40px;
            }
            
            &__title {
                text-align: left;
            }
        }
    }

    &--red {
        padding-top: 131px;
        padding-bottom: 157px;
        background-color: @red;

        .section {
            &__top {
                margin-bottom: 48px;
                h2 {
                    color: #fff;
                }
            }
        }
    }

    &--mainadv {
        padding-top: 132px;
        padding-bottom: 140px;
        background-image: url("../images/common/main-back.png");
        background-position: center;
        background-size: auto 100%;
        background-repeat: no-repeat;
    }

    &__title {
        font-weight: 600;
        font-size: 30px;
        line-height: 110%;
    }

    &__btn {
        display: none;
    }

    &__top {
        justify-content: center;
        margin-bottom: 82px;
        text-align: center;

        &--left {
            text-align: left;
        }
        
        &--space {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 77px;
        }
    }
    

    &--dark {
        padding-top: 81px;
        padding-bottom: 112px;
        background-color: @grey_4;

        .section {
            &__top {
                margin-bottom: 74px;
            }

            &__title {
                text-align: left;
                color: #fff;
            }
        }
    }

    &--similar {
        padding-top: 80px;
        padding-bottom: 0;
    }

    &--inner {
        padding: 82px 0;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .section {
        padding: 8vw 0 7vw;

        &--similar {
            padding-top: 5vw;

            .section {
                &__top {
                    margin-bottom: 2vw;
                }
            }
        }

        &--mainadv {
            padding-top: 9vw;
            padding-bottom: 9vw;
        }

        &--dark {
            padding-top: 5.5vw;
            padding-bottom: 7vw;

            .section {
                &__top {
                    margin-bottom: 5vw;
                }

                &__title {
                    font-size: 2.1vw;
                }
            }
        }

        &__top {
            margin-bottom: 5vw;
        }
    }
}

@media(max-width: 1140px) {
    .section {
        padding-top: 63px;
        padding-bottom: 66px;

        &__top {
            margin-bottom: 44px;

            &--space {
                margin-bottom: 42px;
            }
        }

        &--similar {
            padding-top: 60px;

            .section {
                &__top {
                    margin-bottom: 24px;
                }
            }
        }

        &__title {
            font-size: 22px;
            line-height: 26px; 
        }

        &--interes {
            padding-top: 58px;
            padding-bottom: 85px;
    
            .section {
                &__top {
                    margin-bottom: 40px;
                }
                
                &__title {
                    text-align: left;
                }
            }
        }
      
        &--mainadv {
            padding-top: 64px;
            padding-bottom: 64px;
            background-color: transparent;
            background-image: url("../images/common/main-back-tablet.png");
        }

        &--dark {
            padding-top: 63px;
            padding-bottom: 90px;

            .section {
                &__title {
                    font-size: 22px;
                    line-height: 26px;
                }

                &__top {
                    margin-bottom: 53px;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .section { 
        padding-top: 50px;
        padding-bottom: 50px;

        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
        }

        &__top {
            margin-bottom: 40px;

            .btn {
                display: none;
            }
        }

        &--mainadv {
            padding-top: 64px;
            padding-bottom: 0;
            background-color: transparent;
        }

        &--interes {
            padding-top: 40px;
            padding-bottom: 76px;
    
            .section {
                &__top {
                    margin-bottom: 32px;
                }
                
                &__title {
                    text-align: left;
                }
            }
        }
      

        &--dark {
            padding-top: 40px;
            padding-bottom: 82px;

            .section {
                &__top {
                    margin-bottom: 32px;
                }
            }
        }
    }
}


@media(max-width: 640px) {
    .section {  
        &__title {
            span {
                display: none;
            }
        }

        &--mainadv {
            background-position: center 20px;
            background-size: 100% auto ;
        }
    }
}