.not-found {
    &__wrap {
        background: #1F2F58;
        display: flex;
        padding-top: 150px;
        justify-content: center;
        height: calc(~"100vh - 120px");
        text-align: center;
        color: #fff;
    }

    &__img {
        display: flex;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        pointer-events: none;

        img {
            display: block;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;

        .back {

            .svg-icon {
                margin-right: 12px;

                use {
                    fill: #fff;
                }
            }

            span {
                color: #fff;
            }
            
        }

        a {
            margin: 0 12px;
            color: #fff;

            .svg-icon {
                margin-right: 12px;

                use {
                    fill: #fff;
                }
            }

            span {
                &::before {
                    background-color: #fff;
                }
            }
            
            
        }
    }

    &__text {
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        margin-bottom: 40px;
    }

    &__title {
        font-weight: 600;
        font-size: 144px;
        line-height: 100%;
        margin-bottom: 8px;
        font-family: 'Galderglynn1884Cd';
    }
}

@media(max-height: 768px) {
    .not-found {
        &__img {
            img {
                max-width: 64%;
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .not-found {
        &__title {
            font-size: 9.7vw;
        }

        &__text {
            font-size: 1.7vw;
            margin-bottom: 2.5vw;
        }

        &__wrap {
            height: calc(~"100vh - 8.5vw");
        }

        &__img {
            img {
                max-width: 49%;
            }
        }

        .back {
            .svg-icon {
                width: 10px;
            }

            span {
                font-size: 13px;
            }
        }

        &__link {
            .svg-icon {
                width: 10px;
            }
        }
    }
}

@media (max-width: 960px) {
    .not-found {
        &__title {
            font-size: 100px;
            line-height: 100%;
        }

        &__wrap {
            height: calc(~"100vh - 60px");
        }

        &__text {
            font-size: 17px;
            line-height: 120%;
            margin-bottom: 24px;
        }
    }
}

@media (max-width: 640px) {
    .not-found {
        height: calc(~"100vh - 64px");

        &__img {
            display: none;
        }
    }
}