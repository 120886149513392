.notfound {
    display: flex;
    align-items: center;
    padding-top: 61px;
    padding-bottom: 50px;

    &__body {
        width: 359px;
        padding-bottom: 10px;
    }

    &__text {
        font-weight: 500;
        font-size: 19px;
        letter-spacing: -0.02em;
        line-height: 150%;
        margin-bottom: 40px;
    }

    &__title {
        font-weight: 600;
        font-size: 57px;
        line-height: 68px;
        margin-bottom: 7px; 

        span {
            color: #810D10;
        }
    }
}

@media (min-width:1141px) and (max-width:1440px) {
    .notfound {
        &__img {
            width: 40vw;
        }

        &__body {
            width: 25vw;
        }

        &__title {
            font-size: 4vw;
        }

        &__text {
            font-size: 1.4vw;
        }
    }
}

@media (max-width:1141px) {
    .notfound {
        padding-top: 35px;
        padding-bottom: 0;

        &__img {
            width: 450px;
        }
    }
}

@media (max-width:960px) {
    .notfound {
        padding-top: 35px;
        padding-bottom: 0;
        
        &__title {
            font-size: 40px;
            line-height: 55px;
        }

        &__text {
            font-size: 16px;
            margin-bottom: 22px;
        }
        
        &__img {
            width: 300px;
            flex: none;
        }
    }
}

@media (max-width:640px) {
    .notfound {
        display: block;

        &__body {
            margin-top: -20px;
            width: 100%;
            padding-left: 0;
            text-align: center;
        }

        &__text {
            font-size: 16px;
            line-height: 150%;
        }

        &__title {
            font-size: 36px;
            line-height: 43px;
            margin-bottom: 12px;
        }

        &__img {
            width: 100%;
        }
    }
}