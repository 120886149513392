.slider-common {
    .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 40px;
        position: absolute;
        background-color: transparent;
        border: 0;
        top: 134px;
        z-index: 5;


        .svg-icon {

            use {
                fill: transparent;
                stroke: #717171;
            }
        }

        &.slick-next {
            right: -42px;
        }

        &.slick-prev {
            left: -39px;
        }
    }
}