.map {
    width: 100%;
    height: 100%;

    &__wrap {
        float: left;
        width: calc(~"(100vw - 1276px)/2 + 651px");
        height: calc(~"100vh - 160px");
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .map {
        &__wrap {
            width: 50vw;
            height: calc(100vh - 5vw);
        }
    }
}


@media(max-width: 1140px) { 
    .map {
        &__wrap {
            width: 468px;
            height: calc(~"100vh - 64px");
        }
    }
}

@media(max-width: 960px) {
    .map {
        &__wrap {
            width: 304px;
        }
    }
}

@media(max-width: 640px) {
    .map {
        &__wrap {
            display: none;
        }
    }
}