.history {

    &__wrap {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }
    
    &__item {
        display: flex;
        align-items: center;
        padding-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid #F2F2F2;

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }

        &:first-child {
            padding-top: 0;
        }
    }

    &__doc {
        width: 170px;
        flex: none;

        .doc {
            pointer-events: none;

            &__container {
                flex-direction: column;
                align-items: flex-start;
            }

            &__name {
                font-size: 16px;
                line-height: 153%;
                margin-bottom: 0;
            }

            &__item {
                width: auto;
                padding-right: 0;
                margin-bottom: 16px;
            }

            &__icon {
                width: 20px;
                margin-right: 12px;

                svg {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    

    &__info {
        padding-left: 24px;
        padding-right: 25px;
        flex-grow: 1;

        &-list {
            div {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
            b {
                display: block;
            }
        }

        &-item {
            display: flex;
            margin-bottom: 14px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                width: 190px;
                flex: none;
                font-size: 16px;
                line-height: 153%;
                color: #B5B5B5;
            }
        }
    }

    &__main {
        width: 176px;
        flex: none;

        .btn {
            margin-top: 24px;
        }

        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 132%;
            letter-spacing: -0.01em;
            display: block;
            margin-bottom: 24px;
        }
    }

    &__price {
        font-family: 'Bitter', serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px; 
    }
}


@media(max-width: 1140px) {
    .history {
        &__price {
            font-size: 22px;
            line-height: 26px;
        }

        &__doc {
            .doc {
                &__name {
                    font-size: 14px;
                    line-height: 153%;
                }
            }
        }

        &__info {
            &-list {
                font-size: 14px;
                line-height: 153%;

                b {
                    font-size: 13px;
                    line-height: 153%;
                }
            }

            &-item {
                span {
                    font-size: 14px;
                    line-height: 153%;
                    width: 150px;
                }
            }
        }

        &__main {
            span {
                font-size: 16px;
                line-height: 132%;
                margin-bottom: 20px;
            }

            .btn {
                margin-top: 20px;
            }
        }
    }

}

@media(max-width: 961px) {
    .history {
        &__wrap {
            display: block;
            width: 50%;
            padding-right: 12px;
        }

        &__item {

            padding-bottom: 28px;
            padding-top: 28px;
            
        }

        &__info {
            padding: 24px 0 0 ;

            &-list {
                div {
                    margin-bottom: 8px;
                }
            }

            &-item {
                display: block;
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    font-size: 14px;
                    line-height: 153%;
                    width: auto;
                    margin-bottom: 8px;
                }
            }
        }

        &__item {
            align-items: flex-start;
        }

        &__main {
            span {
                margin-bottom: 16px;
            }

            .btn {
                margin-top: 16px;
            }
        }

        &__doc {
            width: 50%;
            padding-left: 12px;
            padding-top: 5px;
        }
    }
}

@media(max-width: 641px) {
    .history {
        &__item {
            display: block;
        }

        &__doc {
            .doc {
                &__item {
                    display: inline-flex;
                }
            }
        }

        &__main {
            width: 100%;
        }

        &__doc {
            width: 100%;
            padding-top: 0;
            padding-left: 0;
        }

        &__wrap {
            display: block;
            width: 100%;
            padding-right: 0;
            padding-bottom: 24px;
        }
    }
}
