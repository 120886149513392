.address {
    &__item {
        font-size: 16px;
        line-height: 153%;
        letter-spacing: -0.02em;
        color: #fff;
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            color: #fff;
        }

        span {
            margin-bottom: 4px;
            color: @grey_2;
            display: block;
        }
    }
}

@media(max-width: 1140px) {
    .address {
        display: block;

        &__item {
            font-size: 14px;
            line-height: 153%;
            color: #717171;
            width: 100%;
            margin-bottom: 2px;

            &:last-child {
                display: none;
            }

            &:nth-last-child(2) {
                display: none;
            }

            a {
                color: #717171;
            }

            span {
                display: initial;
            }
        }
    }
}