.employee {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px -56px;

    &__item {
        width: calc(~"33.3% - 40px");
        margin: 0 20px 56px;
    }

    &__title {
        font-weight: 600;
        font-size: 17px;
        line-height: 132%;
        padding-left: 8px;
        color: #000000;
        max-width: 80%;
    }

    &__avatar {
        width: 145px;
        height: 145px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        margin-bottom: 24px;
        background-color: #000;
    }

    &__post {
        margin-top: 4px;
        font-weight: 300;
        font-size: 17px;
        line-height: 160%;
        color: #ABABAB;
        padding-left: 8px;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .employee {
        &__avatar {
            width: 10vw;
            height: 10vw;
            margin-bottom: 2.2vw;
        }

        &__item {
            margin-bottom: 4.2vw;
        }

        &__title {
            font-size: 1.1vw;
        }

        &__post {
            font-size: 1.1vw;
        }
    }

}

@media (max-width: 960px) {
    .employee {
        margin: 0 -12px -24px;

        &__avatar {
            width: 100px;
            height: 100px;
            margin-bottom: 16px;
        }   

        &__item {
            width: calc(~"33.3% - 24px");
            margin: 0 12px 24px;
        }

        &__post {
            font-size: 13px;
        }

        &__title {
            font-size: 14px;
            max-width: 100%;
        }
    }
}

@media (max-width: 640px) {
    .employee {
        &__item {
            width: calc(~"50% - 24px");   
        }
    }
}   

@media (max-width: 480px) {
    .employee {
        &__item {
            width: calc(~"100% - 24px");   
            text-align: center;
        }

        &__avatar {
            margin-left: auto;
            margin-right: auto;
        }
    }
}