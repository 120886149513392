.service-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 504px;
    background-image: url(../images/common/serivce-banner.jpg);

    background-position: center;
    background-size: cover;
    padding: 60px 108px 60px 60px;
    border-radius: 18px;
    margin-bottom: 40px;
    position: relative;
    background: linear-gradient(
245.17deg
,#ffd78a 17.08%,#6ecbff 94.41%);


    .bread {
        position: absolute;
        top: 23px;
        left: 59px;
        z-index: 2;
        margin: 0;
    }

    &__title {
        margin: 0 0 25px;
    }

    &__body {
        width: 533px;
        flex: none;
    }

    &__text {
        font-weight: 300;
        font-size: 17px;
        line-height: 160%;
    }

    &__block {
        width: 495px;
        flex: none;
    }

    .search-main {
        &__main {
            overflow: hidden;
            border-radius: 6px;

            input[type=text] {
                padding-left: 22px;
                font-weight: 300;
                font-size: 22px;
                line-height: 140.62%;
            }
        }

        &__submit {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            background-color: transparent;

            .svg-icon {
                use {
                    fill:  #000000;
                }
            }
        }

        &__list {
            margin-top: 32px;

            &-item {
                font-weight: 300;
                font-size: 15px;
                line-height: 160%;
                padding: 6px 16px;
                background-color: rgba(255, 255, 255, 0.4);
            } 
        }
    }
}

@media(min-width: 960px) {
    .service-banner {
        .search-main {
            &__list {
                &-item {
                    &:hover {
                        background-color:  #1A5DD1;
                        color: #fff;
                    }
                } 
            }
        }
    }
}


@media (min-width: 1141px) and (max-width: 1440px) {
    .service-banner {
        height: 36vw;
        padding: 2.2vw 4.5vw;

        .bread {
            top: 1.8vw;
            left: 4.5vw;
        }

        .search-main {
            &__list {
                &-item {
                    margin: 0 2px 4px;
                    font-size: 1.1vw;
                    padding: 7px 11px;
                    line-height: 1.2;
                }
            }

            &__submit {
                width: 4.2vw;
                height: 4.2vw;
                padding: 0;
                justify-content: center;

                .svg-icon {
                    margin: 0;
                    width: 37%;
                    height: auto;
                }
            }

            &__main {
                input[type='text'] {
                    height: 4.2vw;
                    padding-left: 14px;
                    font-size: 1.7vw;
                }
            }
        }

        &__block {
            width: 44%;
        }

        &__title {
            font-size: 4vw;
            margin-bottom: 1.5vw;
        }

        &__text {
            font-size: 1.2vw;
        }

        &__body {
            width: 56%;
            padding-right: 83px;
        }

        &__title {
            font-size: 4vw;
        }
    }
}

@media(max-width: 960px) {
    .service-banner {
        display: block;
        text-align: center;
        margin-left: -32px;
        margin-right: -32px;
        margin-top: -8px;
        border-radius: 0;
        padding: 78px 80px;
        height: auto;
        margin-bottom: 29px;

        .bread {
            top: 8px;
            left: 34px;
        }

        &__body {
            width: 100%;
            padding-right: 0;
        }

        &__title {
            margin-bottom: 16px;
        }

        &__text {
            font-size: 14px;
        }

        &__block {
            width: 480px;
            margin: 0 auto;
            margin-top: 32px;
        }

        .search-main {
            &__list {
                display: none;
            }

            &__submit {
                height: 40px;
                width: 40px;
                padding: 0;
                justify-content: center;

                .svg-icon {
                    width: 18px;
                    height: auto;
                    margin: 0;
                }
            }

            &__main {
                input[type=text] {
                    font-size: 15px;
                    padding-left: 15px;
                    height: 40px;
                }
            }
        }
    }
}


@media(max-width: 640px) {
    .service-banner {
        display: block;
        text-align: left;
        padding: 64px 32px;

        &__block {
            width: 100%;
        }
    }
}


@media(max-width: 480px) {
    .service-banner {
        margin-left: -24px;
        margin-right: -24px;
        padding: 58px 24px 48px;

        .bread {
            left: 24px;
        }
    }
}