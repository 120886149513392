.menu-footer {
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin-bottom: 19px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
            }
        }
    }
}