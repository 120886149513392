.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    padding: 16px 28px;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid transparent;
    text-decoration: none;
    letter-spacing: 0.12em;
    font-family: 'Bitter', serif;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s ease;

    &--mini {
        padding: 9px 15px;
        letter-spacing: 0.08em;
        font-size: 12px;
        line-height: 100%;
    }

    &-not-fon {
        font-family: 'Fira Sans', sans-serif;
        padding: 5px 10px;
        font-size: 14px;
        line-height: 168%;
        letter-spacing: -0.02em;
        line-height: 150%; 
        text-transform: initial;
        font-weight: normal;
        color: inherit;

        &.is-active {
            background-color: #EDE6E6;
        }
    }

    &--file {
        font-size: 14px;
        line-height: 168%;
        letter-spacing: -0.02em;
        font-family: 'Fira Sans', sans-serif;
        text-transform: initial;
        padding: 4px 16px;
        font-weight: normal;
        color: inherit;
    }

    &--grey {
        background-color: #E6E6E6;
    }

    &--big {
        padding: 23px 32px;
        font-size: 17px;
        line-height: 100%;
    }

    &--red {
        color: #fff;
        background-color: @red;
    }

    &--lightred {
        background: #EDE6E6;
        color: #0C0C0C;
    }

    &--outline {
        color: @red;
        border-color: @red; 
        background-color: transparent;
    }

    &--download {
        width: 100%;
        text-transform: initial;
        font-size: 16px;
        letter-spacing: -0.02em;
        line-height: 153%;
        background-color: #E6E6E6;
        height: 64px;

        .svg-icon {
            margin-right: 16px;

            use {
                fill: transparent;
                stroke: #0C0C0C;
            }
        }
    }

    &--upload {
        width: 100%;
        text-transform: initial;
        font-size: 16px;
        letter-spacing: -0.02em;
        line-height: 153%;
        background-color: #E6E6E6;
        height: 64px;

        .svg-icon {
            margin-right: 16px;

            use {
                fill: transparent;
                stroke: #0C0C0C;
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .btn {
        font-size: 1vw;
        padding: 13px 19px;

        &--upload { 
            height: 4vw;
            
            .svg-icon {
                width: 1.2vw;
                height: auto;
                margin-right: 10px;
            }
        }
    }
}

@media(min-width: 960px) {
    .btn {
        &--red {
            &:hover {
                color: @red;
                border-color: @red;
                background-color: #fff;
            }
        }

        &--outline {
            &:hover {
                color: #fff;
                border-color: @red; 
                background-color: @red;
            }
        }

        &--lightred {
            &:hover {
                color: #fff;
                background-color:  @red;
            }
        }
    }
}

@media(max-width: 1140px) {
    .btn {
        font-size: 12px;
        line-height: 100%;
        padding: 11px 27px;
        
        &--big {
            font-size: 14px;
            line-height: 100%; 
            padding: 16px 22px;
        }
    }
}

