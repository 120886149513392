.main-info {
    display: flex;
    margin: 0 -24px 0;

    &__item {
        width: 33.3%;
        margin: 0 24px;
    }

    &__name {
        font-weight: 600;
        font-size: 39px;
        line-height: 110%;
        margin-bottom: 24px;
    }

    &__text {
        font-weight: 300;
        font-size: 17px;
        line-height: 160%;
        
        a {
            color: #1D71B8;
            text-decoration: none;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .main-info {
        &__name {
            font-size: 2.8vw;
            margin-bottom: 1.8vw;
        }

        &__text {
            font-size: 1.22vw;
        }
    }
}

@media(max-width: 960px) {
    .main-info {
        display: block;
        margin: 0 auto;
        width: 83.2%;

        &__item {
            margin: 0 0 40px;
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }

        

        &__text {
            font-size: 14px;
        }

        &__name {
            font-size: 25px;
            margin-bottom: 8px;
        }
    }
}

@media(max-width: 640px) {
    .main-info {
        width: 100%;

    }
}