.price-block {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    &__price {
        font-family: 'Bitter', serif;
        font-weight: 600;
        font-size: 57px;
        line-height: 68px;
        letter-spacing: initial;
        margin-bottom: 4px;
    }

    &__total {
        font-weight: 600;
        font-size: 16px;
        line-height: 153%;
        letter-spacing: -0.01em;
    }

    &__main {
        p {
            font-size: 16px;
            line-height: 153%;
            margin: 0;
        }
    }

    &__info {
        padding-left: 15px;
        text-align: center;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .price-block {
        &__price {
            font-size: 3.65vw;
            line-height: 1.2;
        }
        
        &__main {
            p {
                font-size: 1.1vw;
            }
        }
    }
}

@media(max-width: 1140px) {
    .price-block {
        text-align: center;

        &__price {
            font-size: 36px;
            line-height: 43px;
        }

        &__total {
            order: 1;
            font-weight: normal;
            font-size: 14px;
            line-height: 153%;
        }
        
        &__info {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 50%;
            padding: 0 8px;

            .count {
                order: 2;
            }
        }

        &__main {
            width: 50%;
            padding: 0 8px;

            p {
                font-size: 14px;
                line-height: 153%;  
            }
        }
    }
}

@media(max-width: 960px) { 
    .price-block {
        display: block;

        &__main {
            width: 100%;
            padding-bottom: 24px;
        }

        &__info {
            width: 100%;
        }
    }
}