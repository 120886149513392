.tab {

    &__img {
        display: flex;
        align-items: center;
        margin-left: -30px;
        margin-right: -30px;
        margin-bottom: 46px;

        &-item {
            width: 33.3%;
            padding: 0 30px;
        }

        img {
            opacity: 0.5;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__icon {
        display: none;
        position: relative;

        &::before {
            content:" ";
            position: absolute;
            width: 12px;
            height: 12px;
            background: #992B2E;
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.3s ease;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 153%;
        letter-spacing: -0.02em;
        margin-bottom: 48px;
        
        &:last-child {
            margin-bottom: 0;
        }

        h4 {
            margin-bottom: 16px;
        }

        .btn {
            margin-top: 24px;
        }
    }

    &__slider {
        margin-bottom: 36px;

        .btn {
            display: none;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__block {
        padding-bottom: 25px;
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__content {
        width: 800px;
    }



    &__slider {
        h4 {
            margin-bottom: 32px; 
        }

        .slider-common .slick-arrow {
            top: -66px;
            padding: 0;

            &.slick-next {
                right: 0;
            }

            &.slick-prev {
                left: initial;
                right:  42px;
            }
        }

        .case {
            &__slider {
                .slick-list {
                    overflow: hidden;
                    margin: 0 -15px;
                }
            }
        }
    }

    &__control {
        display: flex;
        align-items: center;
    }

    &__aside {
        width: 279px;

        h5 {
            margin-bottom: 40px;
        }
    }

    &__container {
        padding-top: 70px;
        padding-bottom: 85px;
        background-color: #fff;

        .container {
            display: flex;
            justify-content: space-between;
        }
    }

    &__link {
        display: inline-flex;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        padding-bottom: 12px;
        border-bottom: 3px solid transparent;
        transition: border-bottom 0.3s, color 0.3s ease;
        margin-right: 40px;

        &:last-child {
            margin-right: 0;
        }

        &.is-active {
           border-bottom-color: @red;

           &:hover {
                color: #0c0c0c;
                border-bottom-color: @red;
            }
        }
    }

    &--mini {
        margin-top: 48px;

        .tab {
            &__container {
                padding: 0;
                background-color: transparent;
            }

            &__link {
                margin-right: 32px;
            }

            &__content {
                width: auto;
            }

            &__block {
                padding: 40px 48px 46px;
                background-color: #F2F2F2;

                &--empty {
                    padding: 0;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .tab {
        &__link {
            &:hover {
                border-bottom-color: transparent;
                color: @red;
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .tab {
        &__text {
            font-size: 1.1vw;
            margin-bottom: 33px;
        }

        &__img {
            margin-bottom: 3.5vw;
        }

        &--mini {
            .tab {
                &__block {
                    padding: 31px 39px 40px;
                }
            }
        }

        &__container {
            padding-top: 4vw;
            padding-bottom: 4vw;
        }

        &__content {
            width: 55.5vw;
        }

        &__link {
            margin-right: 24px;
            font-size: 0.95vw;
            padding-bottom: 2px;
        }

        &__aside {
            width: 19vw;
        }
    }
}

@media(max-width: 1140px) {
    .tab {
        &__container {
            padding-bottom: 56px;
            padding-top: 56px;
        }

        &__slider {
            .case {
                &__slider {
                    .slick-list {
                        margin: 0 -10px;
                    }

                    .slick-slide {
                        padding: 0 10px;
                    }
                }
            }
        }

        &__link {
            font-size: 13px;
            line-height: 100%;
            padding-bottom: 10px;
            margin-right: 32px;
        }

        &__content {
            width: 100%;
        }

        &--mini {
            margin-top: 32px;

            .tab {
                &__block {
                    padding: 32px;
                }
            }
        }

        &--card {
            .tab {
                &__content {
                    padding-right: 41px;
                    width: calc(~"100% - 277px");

                }
                &__aside {
                    width: 277px;
                    flex: none;
                }
            }
        }
    }
}

@media(max-width: 960px) { 
    .tab {
        &__control {
            &-item {
                margin-right: 18px;
            }
        }

        

        &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            margin-right: 6px;
            flex: none;
            border: 2px solid #EDE6E6;
            border-radius: 50%;
            margin-right: 6px;
        }

        &__text {
            margin-bottom: 30px;
        }

        &__link {
            font-size: 14px;
            line-height: 100%; 
            margin-right: 48px;
        }

        &__container {
            padding-top: 32px;
            padding-bottom: 32px;
        }

        &--card {
            .tab {
                &__container {
                    padding-bottom: 0;

                    .container {
                        display: block;
                    }
                }

                &__aside {
                    display: none;
                    width: calc(~"100% + 80px");
                    padding-top: 50px;
                    background-color: #F2F2F2;
                    margin: 0 -40px;
                    padding-left: 40px;
                    padding-right: 40px;

                    h5 {
                        font-size: 22px;
                        line-height: 26px; 
                        margin-bottom: 32px; 
                    }
                }

                &__content {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        &--mini {
            .tab {
                &__control {
                   flex-direction: column;
                   align-items: flex-start;
                   margin-bottom: 24px;
                }

                &__block {
                    padding: 0;
                    background-color: transparent;
                }

                &__link {
                    display: inline-flex;
                    align-items: center;
                    padding: 0;
                    margin: 0;
                    font-weight: normal;
                    font-family: 'Fira Sans', sans-serif;
                    border: 0;
                    letter-spacing: -0.02em;

                    margin-bottom: 16px;
                    font-size: 14px;
                    line-height: 153%;
                    text-transform: inherit;
                    margin-bottom: 16px;

                    &.is-active {
                        .tab {
                            &__icon {
                                &::before {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

@media(max-width: 640px) { 
    .tab {
        &__link {
            font-size: 13px;
            line-height: 100%;
            padding-bottom: 10px;
            margin-right: 24px;
            white-space: nowrap;
        }

        &__container {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        &__control {
            padding-right: 0;
            overflow-x: auto;
            width: 100%;
        }
    }
}

@media(max-width: 480px) { 
    .tab {
        &__container {
            padding-top: 32px;
            padding-bottom: 32px;
        }

        &__slider {
            margin-bottom: 32px;

            .btn {
                padding: 16px 18px;
                display: inline-flex;
            }

            .video {
                &__slider {
                    

                    h4 {
                        margin-bottom: 30px;
                    }

                    .video {
                        &__name {
                            font-size: 18px;
                            line-height: 150%;
                            margin-bottom: 2px;
                        }

                        &__icon {
                            width: 32px;
                            height: 32px;
                            margin-right: 8px;

                            .svg-icon {
                                width: 11px;
                                height: auto;
                            }
                        }

                        &__img {
                            height: 56.2vw;
                            margin-bottom: 12px;
                        }
                    }

                    &>div {
                        display: none;
                        margin-bottom: 32px;
                        
                        &:nth-child(1) {
                            display: block;
                        }
    
                        &:nth-child(2) {
                            display: block;
                        }
                    }
                }

            }

            .case {
                &__slider {
                    

                    h4 {
                        margin-bottom: 30px;
                    }

                    .case {
                        &__name {
                            font-size: 16px;
                            line-height: 150%;
                            margin-bottom: 8px;
                        }

                        &__img {
                            height: 53.2vw;
                        }
                    }

                    &>div {
                        display: none;
                        margin-bottom: 32px;
                        
                        &:nth-child(1) {
                            display: block;
                        }
    
                        &:nth-child(2) {
                            display: block;
                        }
                    }
                }

            }
        }
    }
}