.people {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -25px;
    margin-left: -50px;
    margin-right: -50px;

    &__item {
        width: 25%;
        padding: 0 50px;
        margin-bottom: 25px;
    }

    &__avatar {
        width: 160px;
        height: 160px;
        margin: 0 auto 26px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
    }

    &__name {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        font-family: 'Bitter', serif;
        margin-bottom: 8px;
        text-align: center;
    }

    &__post {
        text-align: center;
        font-weight: 300;
        font-size: 16px;
        line-height: 153%;
        letter-spacing: -0.02em;
        color: @grey_2;
    }
}

@media(max-width: 960px) {
    .people {
        display: block;

        &__item {
            width: 100%;
            text-align: center;
            margin-bottom: 32px;
            
            &:last-child {
                margin-bottom: 0;
            }
        }

        &__avatar {
            width: 106px;
            height: 106px;
            margin-bottom: 14px;
        }

        &__name {
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 4px;
        }

        &__post {
            font-weight: 300;
            font-size: 14px;
            line-height: 153%;
        }
    }
}