.wrapper {
    padding-top: 160px;
    padding-bottom: 488px;

    &__body {
        width: 100%;
    }

    &--index {
        .wrapper {
            &__container {
                padding-bottom: 0 !important;
            }
        } 
    }

    &__wrap {
        background-color: #fff;
        position: relative;
        z-index: 5;
    }

    &__content {
        display: flex;

        &--order {
            display: block;
            overflow: hidden;

            .wrapper {
                &__body {
                    float: left;
                    padding-right: 136px;
                    width: calc(~"100% - 384px");

                    h1 {
                        margin-bottom: 22px;
                    }
                }

                &__aside {
                    float: left;
                    width: 384px;
                    padding-top: 16px;
                }
            }
        }

        &--contact {
            overflow: hidden;

            .top-page {
                margin-bottom: 121px;
            }

            .wrapper {
                &__body {
                    float: left;
                    padding-right: 120px;
                    padding-bottom: 128px;
                    width: 608px;
                    margin-left: calc(~"(100vw - 1276px)/2");

                    h1 {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        &--lk {
            overflow: hidden;
            padding-top: 10px;
            padding-bottom: 10px;
           

            .wrapper {
                &__aside {
                    width: 515px;
                    padding-right: 27px;
                    margin-right: -15px;
                    flex: none;
                }

                &__body {
                    padding-right: 135px;
                    width: calc(~"100% - 515px");
                }
            }
        }
    }

    &__container {
        padding-bottom: 118px;

        &--pb0 {
            padding-bottom: 0 !important;
        }

        &--grey {
            background: @bg;
        }
    }

    &__body {
        &--full {
            width: 100%;
        }
        
        &.col-75 {
            width: 75%;
        }
    }

    &__aside {
        &.col-25 {
            width: 25%;
        }
    }

    &__body {
        h1 {
            margin-bottom: 56px;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .wrapper {
        padding-top: 11vw;

        &__body {
            h1 {
                font-size: 3vw;
                line-height: 1.1;
                margin-bottom: 3vw;
            }
        }
        

        &__content {
            &--order {
                .wrapper {
                    &__aside {
                        width: 32%;
                    }

                    &__body {
                        padding-right: 100px;
                        width: 68%;
                    }
                }
            }

            &--lk {

                .wrapper {
                    &__body {
                        padding-right: 81px;
                        width: 63vw;
                    }

                    &__aside {
                        width: 37vw;
                        padding-right: 27px;
                        margin-right: -15px;
                    }
                }
            }

            &--contact {
                .top-page {
                    margin-bottom: 5vw;
                }

                .wrapper__body {
                    margin-left: 0;
                    padding-left: 8vw;
                    width: 50vw;
                    padding-right: 3vw;
                }
            }
        }

        &__container {
            padding-bottom: 5vw;
        }
    }
}



@media(max-width: 1140px) { 
    .wrapper {
        padding-bottom: 467px;
        padding-top: 72px;

        &__content {
            &--catalog {
                .wrapper__body.col-75 {
                    width: calc(~"100% - 241px");
                    padding-right: 60px;
                }
            }

            &--contact {
                .top-page {
                    margin-bottom: 15px;
                }

                .wrapper {
                    &__body {
                        float: left;
                        padding-right: 99px;
                        padding-left: 40px;
                        width: calc(~"100% - 468px");
                        margin-left: 0;

                        h1 {
                            margin-bottom: 25px;
                        }
                    }
                }
            }

            &--lk {
                .wrapper {
                    &__aside {
                        width: 425px;
                    }

                    &__body {
                        padding-right: 57px;
                        width: calc(~"100% - 425px");
                    }
                }
            }

            &--contanct {

            }
        }

        &--index {
            padding-top: 72px;
        }

        
    }
}

@media(max-width: 1140px) {
    .wrapper {
    

        &__content {
            &--order {
                .wrapper {
                    &__body {
                        width: calc(~"100% - 277px");
                        padding-right: 49px;
                    }

                    &__aside {
                        padding-top: 56px;
                        width: 277px;
                    }
                }
            }
        }

        &__body {
            h1 {
                margin-bottom: 25px;
            }
        }
    }
}

@media(max-width: 960px) {
    .wrapper {
        padding-top: 64px;
        padding-bottom: 515px;

        &__container {
            padding-bottom: 82px;
        }

        &__content {

            &--order {
                .wrapper {
                    &__body {
                        width: 100%;
                        padding-right: 0;
                    }
                    
                    &__aside {
                        display: none;
                    }
                }
            }

            &--contact {
                .top-page {
                    margin-bottom: 15px;
                }

                .wrapper {
                    &__body {
                        width: calc(~"100% - 304px");
                        float: left;
                        padding-right: 38px;
                        padding-left: 24px;

                        h1 {
                            margin-bottom: 25px;
                        }
                    }

                    &__aside {
                        display: none;
                    }
                }
            }

            &--catalog {
                .wrapper__body.col-75 {
                    width: 100%;
                    padding-right: 0;
                }
            }

            &--lk {
                display: flex;
                flex-direction: column;

                .wrapper {
                    &__aside {
                        padding-top: 53px;
                        width: 100%;
                    }

                    &__body {
                        padding-right: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .wrapper {
        padding-top: 56px;
        
        &__container {
            padding-bottom: 68px;
        }
        
        &__content {

            &--contact {
                .wrapper {
                    &__body {
                        width: 100%;
                        padding-right: 32px;
                        padding-left: 32px;
                    }
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .wrapper {
        padding-top: 48px;

        &__container {
            padding-bottom: 46px;
        }
        
        
        &__content {

            &--contact {
                .wrapper {
                    &__body {
                        padding-right: 24px;
                        padding-left: 24px;
                    }
                }
            }
        }
    }
}

@media(max-height: 750px) {
    .wrapper {
        padding-bottom: 0;
    }

    .footer {
        position: relative;
    }
}