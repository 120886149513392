
.header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: 0px 4px 48px rgba(0, 0, 0, 0.05);
    z-index: 1500;
    background-color: #fff;
    

    &__top {
        display: flex;
        align-items: center;
        background-color: @red;
        height: 56px;
    }

    &__main {
        position: relative;
        z-index: 5;
        display: flex;
        align-items: center;
    }

    &__info {
        display: flex;
        align-items: center;

        .phone {
            color: @light;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px; 
            margin-right: 39px;

           
        }
    }
    
    &__bottom {
        display: flex;
        align-items: center;
        position: relative;
        height: 104px;
    }

    &__control {
        position: relative;
        z-index: 5;
        
        .phone {
            display: none;

            &__icon {
                .svg-icon {
                    use {
                        fill: #fff;
                    }
                }
            }
        }
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;
        width: 100%;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .header {
        .logo {
            width: 9.5vw;
        }
        
        &__top {
            height: 4vw;
        }

        &__bottom {
            height: 7vw;
        }

        &__info {
            .phone {
                font-size: 1.5vw;
            }
        }

        .container {
            padding: 0 2.8vw;
        }
    }
}

@media(max-width: 1140px) {
    .header {
        &__top {
            display: none;
        }

        &__bottom {
            .lang {
                display: none;
            }

            .container {
                padding-right: 0;
            }
        }

        &__control {
            display: flex;
            align-items: center;
            height: 100%;

            .phone {
                font-weight: 600;
                font-size: 18px;
                line-height: 100%;
                color: #810D10;
                letter-spacing: 0.06em;
                text-transform: uppercase;
                margin-right: 36px;
                padding-bottom: 2px;
                display: inline-flex;
                border-bottom: 3px solid fade(@red, 30);

                &__icon {
                    display: none;
                }
            }
        }

        &__main {
            .logo {
                img {
                    width: 116px;
                }
            }
        }

        &__bottom {
            height: auto;

            .container {
                height: 72px;
                padding-left: 18px;
            }
        }
    }
}

@media(max-width: 960px) {
    .header {
        &__main {
            .logo {
                img {
                    width: 90px;
                }
            }
        }
        &__bottom {
            .container {
                padding-left: 18px;
                height: 64px;
            }
        }

        &__control {
            .phone {
                border: 0;
                margin-right: 22px;

                span {
                    display: none;
                }

                &__icon {
                    width: 43px;
                    height: 43px;
                    background-color: #E3D8D8;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .header {
        &__main {
            .logo {
                img {
                    width: 77px;
                }
            }
        }

        &__bottom {

            .container {
                padding-left: 16px;
                height: 56px;
            }
        }

        &__control {
            .phone {
                border: 0;
                margin-right: 16px;

                span {
                    display: none;
                }

                &__icon {
                    width: 37px;
                    height: 37px;

                    .svg-icon {
                        width: 18px;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .header {
        &__main {
            .logo {
                img {
                    width: 69px;
                }
            }
        }


        &__bottom {
            .container {
                padding-left: 14px;
                height: 48px;
            }
        }

        &__control {
            .phone {
                margin-right: 15px;

                &__icon {
                    width: 32px;
                    height: 32px;

                    .svg-icon {
                        width: 16px;
                    }
                }
            }
        }
    }
}
