.pagination {
    display: flex;
    align-items: center;
    margin-top: 60px;

    &__separation {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 4px;
        font-size: 14px;
        line-height: 16px;
        color: #0C0C0C;
        width: 32px;
        height: 32px;
        font-family: 'Roboto', sans-serif;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2px;
        margin: 0 4px;
        font-family: 'Roboto', sans-serif;
        width: 32px;
        height: 32px;
        font-size: 14px;
        line-height: 16px;
        color: #0C0C0C;
        border: 1px solid #EDE6E6;
        background-color: transparent;
        transition: border-color 0.3s, background-color 0.3s ease;
        
        &.is-active {
            background-color: @red;
            color: #fff;
        }
    }
}

@media(min-width: 960px) {
    .pagination {
        &__item {
            &:hover {
                color: @red;
            }
        }
    }
}