.event {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        background-color: #F2F2F2;
        margin-bottom: 4px;
        padding: 16px 32px;
        min-height: 133px;
        color: #000000;
        transition: background-color 0.3s, color 0.3s ease;
    }

    &__body {
        padding-right: 35px;
    }

    &__info {
        margin-right: 24px;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.1em;
        font-family: Galderglynn1884Cd;
    }

    &__btn {
       .btn {
            width: 126px;
        }

        &-wrap {
            display: inline-flex;
            align-items: center;
            flex: none;
        }
    }

    &__date {
        display: inline-flex;
        align-items: center;
        font-weight: 300;
        font-size: 17px;
        line-height: 160%;
        
        .svg-icon {
            flex: none;
            width: 14px;
            height: auto;
            display: block;
            margin-right: 8px;
            margin-top: -2px;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 30px;
        line-height: 110%; 
        margin-bottom: 16px;
    }

    &__type {
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.1em;
        font-family: Galderglynn1884Cd;
        margin-bottom: 8px;
    }
}

@media(min-width: 960px) {
    .event {
        &__item {
            &:hover {
                color: #fff;
                background-color: #fff;

                .event {
                    &__date {
                        .svg-icon {
                            use {
                                fill: #fff;
                            }
                        }
                    }

                    &__info {
                        color: #fff;
                    }

                    &__btn {
                        .btn {
                            &--blue {
                                background-color: #fff;
                                color: #fff;
                                border-color: #fff;
                            }

                            &--out-dark {
                                color: #fff;
                                border-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .event {
        &__item {
            padding: 1.5% 2.7%;
            min-height: initial;
        }

        &__btn {
            .btn {
                font-size: 1.1vw;
                width: 9vw;
                padding: 1vw 1vw;
                line-height: 1;
            }
        }

        &__info {
            font-size: 1vw;
            margin-right: 1.7vw;
        }

        &__name {
            font-size: 2.1vw;
            margin-bottom: 1vw;
        }

        &__date {
            font-size: 1.2vw;

            .svg-icon {
                width: 1vw;
                margin-top: -0.2vw;
            }
        }

        &__type {
            font-size: 1.0vw;
            margin-bottom: 0.6vw;
        }
    }
}

@media(max-width: 960px) {
    .event {
        margin: 0 -32px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-top: 0;

        &__btn {
            .btn {
                font-size: 13px;
                padding: 8px 12px;
            }
        }

        &__type {
            font-size: 12px;
            margin-bottom: 4px;
        }

        &__date {
            font-size: 14px;

            .svg-icon {
                width: 12px;
            }
        }

        &__item {
            margin-bottom: 2px;
        }

        &__name {
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
}

@media(max-width: 480px) {
    .event {
        &__item {
            display: block;
        }

        &__body {
            padding-right: 0;
        }

        &__btn {
            &-wrap {
                margin-top: 16px;
            }
        }
    }
}