.founder {
    display: inline-flex;
    align-items: center;

    &__icon {
        margin-right: 21px;
        flex: none;
    }

    &__text {
        font-weight: 300;
        font-size: 15px;
        line-height: 160%;
        color: #FFFFFF;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .founder {
        &__icon {
            width: 4vw;
            margin-right: 1.6vw;
        }

        &__text {
            font-size: 1.1vw;
        }
    }
}


@media(max-width: 960px) {
    .founder {
        &__icon {
            width: 41px;
            margin-right: 14px;
        }

        &__text {
            font-size: 12px;
        }
    }
}