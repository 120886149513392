.mobile-catalog {
    display: none;

    &__btn {
        display: none;
    }
}

@media(max-width: 1140px) {
    .mobile-catalog {
        display: block;
        position: fixed;

        left: 0;
        right: 0;
        top: 72px;
        opacity: 1;
        z-index: 10;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }


        ul {
            display: flex;
            padding: 0;
            margin: 0;
            
            li {
                list-style: none;
                width: 25%;

                &:last-child {
                    a {
                        border: 0;
                    }
                }
                

                a {
                    position: relative;
                    padding-left: 15px;
                    padding-right: 15px;
                    display: flex;
                    border-right: 1px solid #fff;
                    align-items: flex-end;
                    justify-content: center;
                    text-align: center;
                    padding-bottom: 27px;
                    height: 197px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #FFFFFF;
                    font-family: 'Bitter', serif;
                    width: 100%;
                    background-position: center;
                    background-size: cover;

                    &::before {
                        content:" ";
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background: linear-gradient(360deg, rgba(0, 0, 0, 0.74) 0%, rgba(0, 0, 0, 0) 61.68%);
                        position: absolute;
                    }

                    span {
                        display: flex;
                        align-items: flex-start;
                        height: 34px;
                        position: relative;
                        z-index: 2;
                    }
                }
            }
        }

        &__btn {
            display: inline-flex;
            align-items: center;
            margin-left: 27px;
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            font-family: 'Bitter', serif;
            color: @red;
            cursor: pointer;
            letter-spacing: 0.08em;
            text-transform: uppercase;
            margin-top: 4px;

            &.is-active {
                .mobile-catalog {
                    &__control {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            span {
                margin-left: 5px;
            }
        }

        &__control {
            margin-left: 2px;
            svg {
                display: block;
                transition: transform 0.3s ease;
            }
        }
    }
}

@media(max-width: 960px) {
    .mobile-catalog {
        top: 64px;

        
        &__btn {
            font-size: 12px;
            line-height: 100%; 
            margin-left: 24px;
        }

        ul {
            li {
                a {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .mobile-catalog {
        top: 56px;

        &__btn {
            margin-left: 20px;

            span {
                display: none;
            }
        }

        ul {
            flex-direction: column;

            li {
                width: 100%;

                &:last-child {
                    a {
                        border-bottom: 0;
                    }
                }

                a {
                    border: 0;
                    height: 113px;
                    padding-bottom: 44px;
                    font-size: 14px;
                    line-height: 17px;
                    border-bottom: 1px solid #fff;

                    &::before {
                        background: linear-gradient(360deg, rgba(0, 0, 0, 0.74) 0%, rgba(0, 0, 0, 0) 61.68%);
                    }

                    span {
                        height: auto;
                    }
                }
            }
        }

        
    }
}