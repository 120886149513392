.check {
    display: flex;
    flex-direction: column;

    &__item {
        display: inline-flex;
        align-items: center;
        margin-bottom: 16px;
        position: relative;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
            opacity: 0;

            &:checked {
                &~.check {
                    &__control {
                        .svg-icon {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 12px;
        padding-top: 2px;
        padding-left: 2px;
        flex: none;
        border: 2px solid #EDE6E6;

        .svg-icon {
            display: block;
            opacity: 0;
            transition: opacity 0.3s ease;

            use {
                fill: @red;
            }
        }
    }

    &__label {
        font-weight: 600;
        font-size: 16px;
        line-height: 153%; 
        letter-spacing: -0.01em;
    }

    &--light {
        .check {
            &__label {
                letter-spacing: -0.02em;
                font-weight: normal;
            }
        }
    }
}

@media(max-width: 1140px) {
    .check {
        &__item {
            margin-bottom: 23px;
        }
    }
}