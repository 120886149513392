.check-service {
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    &:last-child {
        margin-bottom: 0;
    }

    .service-item {
        padding-right: 50px;
    }

    &__control {
        width: 30px;
        height: 30px;
        position: relative;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        border: 1px solid #000000;
        transition: border-color 0.3s ease;

        input[type="checkbox"] {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;

            &:checked {
                &~.check-service {
                    &__control {
                        &-wrap {
                            .svg-icon {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .svg-icon {
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;
        }
    }

    &--disabled {
        .check-service {
            &__control {
                border-color:  #ABABAB;

                .svg-icon {
                    opacity: 1;
                    use {
                        fill: #ABABAB;
                    }
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .check-service {
        margin-bottom: 1.4vw;

        &__control {
            width: 2.5vw;
            height: 2.5vw;

            .svg-icon {
                width: 1.5vw;
            }
        }
    }
}