.page {
    .wrapper {
        &__body {
            width: 800px;
            flex: none;
        }

        &__aside {
            padding-left: 136px;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .page {
        .wrapper {
            &__body {
                width: 66%;
            }
    
            &__aside {
                padding-left: 11.5%;
            }
        }
    }
}

@media(max-width: 1140px) {
    .page {
        .wrapper {
            &__body {
                width: calc(~"100% - 277px");
                padding-right: 49px;
                flex: none;
            }
    
            &__aside {
                width: 277px;
                padding-left: 0;
            }
        }

        &--detail {
            .wrapper {
                &__body {
                    width: calc(~"100% - 236px");
                    padding-right: 68px;
                    flex: none;

                    h1 {
                        margin-bottom: 73px;
                    }
                }
        
                &__aside {
                    width: 236px;
                    padding-left: 0;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .page {
        .wrapper {
            &__body {
                width: 100%;
                padding-right: 0;
            }
    
            &__aside {
                display: none;
            }
        }

        &--detail {
            .wrapper {
                &__body {
                    h1 {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}