.faq {
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &.is-active {
            .plus-minus-toggle {

            }
        }
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
    }

    &__item {
        width: 100%;
        border-bottom: 1px solid #EDE6E6;
    }

    &__body {
        padding-top: 15px;
        padding-bottom: 28px;
        display: none;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__name {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.01em;
    }

    &--inner {
        max-width: 100%;
        width: 988px;
        margin-bottom: 62px;
    }
}

.plus-minus-toggle {
    cursor: pointer;
    height: 14px;
    position: relative;
    width: 14px;
    
    &:before,
    &:after {
        background-color:  #B5B5B5;
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: 6px;
        width: 14px;
        transition: transform 500ms, background-color 0.3s ease;
    }

    &:after {
        transform-origin: center;
    }

    &.collapsed {
        &:after {
            transform: rotate(90deg);
        }
        &:before {
            transform: rotate(180deg);
        }
    }
}

@media(min-width: 960px) {
    .faq {
        &__top {
            &:hover {
                .plus-minus-toggle {
                    &:before,
                    &:after {
                        background-color: #0C0C0C;
                    }
                }
            }
        }
    }
}

@media(max-width: 480px) { 
    .faq {
        &__name {
            font-size: 16px;
            line-height: 150%; 
        }
    }
}