.top-page {
    padding-top: 12px;
    margin-bottom: 68px;
    position: relative;

    &__wrap {
        position: relative;
        text-align: center;
        margin-bottom: 140px;
        padding-bottom: 47px;
        background-position: center;
        background-size: cover;

        .top-page {
            padding-top: 0;
        }

        .bread {
            margin-bottom: 92px;
            padding-top: 13px;

            ul {
                justify-content: center;

                li {
                    a {
                        color: #fff;

                        .svg-icon use {
                            stroke: #fff;
                        }
                    }

                    span {
                        color: #fff;
                    }
                }
            }
        }

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.92) 0%, rgba(0, 0, 0, 0) 100%);
        }

        h1 {
            font-size: 57px;
            line-height: 68px;
            margin-bottom: 36px;
            color: #fff;
        }
    }

   

    &__text {
        font-weight: 600;
        font-size: 20px;
        line-height: 150%; 
        font-family: 'Bitter', serif;
        color: #fff;
        max-width: 621px;
        margin: 0 auto;
    }
}

@media(max-width: 1140px) {
    .top-page {
        padding-top: 3px;
        margin-bottom: 28px;
    }
}


@media(max-width: 960px) {
    .top-page {
        padding-top: 8px;
        margin-bottom: 30px;

        &__text {
            font-size: 14px;
            line-height: 150%;  
        }

        &__wrap {
            margin-bottom: 48px;
            padding-bottom: 71px;
            padding-top: 56px;
            letter-spacing: initial;

            .bread {
                display: none;
            }

            h1 {
                font-size: 36px;
                line-height: 43px;
                margin-bottom: 24px;
            }
        }
    }
}


@media(max-width: 640px) {
    .top-page {
        margin-bottom: 24px;
    }
}