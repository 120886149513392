h1 {
    font-weight: 600;
    font-size: 42px;
    line-height: 50px;
    margin: 0;
    font-family: 'Bitter', serif;
    letter-spacing: initial;
}

h2 {
    font-weight: 600;
    font-size: 42px;
    line-height: 50px;
    margin: 0;
    font-family: 'Bitter', serif;
    letter-spacing: initial;
}

h3, .h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    margin: 0;
    font-family: 'Bitter', serif;
    letter-spacing: initial;
}

h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    font-family: 'Bitter', serif;   
    letter-spacing: initial;
}

h5 {
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    margin: 0;
    font-family: 'Bitter', serif; 
}

@media (min-width: 1141px) and (max-width: 1440px) {
    h1 {
        font-size: 3vw;
        line-height: 1.1;
    }

    h2 {
        font-size: 3vw;
        line-height: 1.2;
    }

    h4 {
        font-size: 1.6vw;
        line-height: 1.2;
    }

    h3, .h3  {
        font-size: 2.2vw;
    }

    h5 {
        font-size: 1.3vw;
    }
}

@media(max-width: 1140px) {
    h1 {
        font-size: 26px;
        line-height: 31px;  
    }

    h2 {
        font-size: 26px;
        line-height: 31px;
    }

    h3, .h3  {
        font-size: 22px;
        line-height: 26px;
    }
}

@media(max-width: 960px) {
    h3, .h3  {
        font-size: 22px;
        line-height: 26px;
    }
}