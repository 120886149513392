.news {

    &__img {
        height: 530px;
        background-position: center;
        background-size: cover;
        margin-bottom: 24px;
    }

    &__date {
        font-size: 12px;
        line-height: 150%;
        color: @grey;
        letter-spacing: 0.13em;
        text-transform: uppercase;
        margin-bottom: 8px;
    }

    &__name {
        color: @dark;
        font-weight: bold;
        font-size: 21px;
        line-height: 132%;
    }

    &__item {
        display: block;
        margin-bottom: 56px;

        &--line {
           display: flex;
           align-items: center;

           .news {
                &__img {
                    width: 240px;
                    height: 240px;
                    margin-right: 40px;
                    margin-bottom: 0;
                    flex: none;
                }

                &__date {
                    margin-bottom: 4px;
                }

                &__text {
                    font-size: 15px;
                    line-height: 182%;
                    color: @dark;
                }

                &__link {
                    margin-top: 16px;
                    display: inline-flex;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 150%;
                    color: @green;
                    letter-spacing: 0.13em;
                    text-transform: uppercase;

                }

                &__name {
                    font-size: 19px;
                    line-height: 132%;
                    margin-bottom: 16px;
                }
            }
       }
    }

}

@media(max-width: 960px) {
    .news {
        &__img {
            height: 386px;
        }   

        &__date {
            font-size: 10px;
            line-height: 150%;
        }

        &__name {
            font-size: 15px;
            line-height: 132%;
        }

        &__item {
            margin-bottom: 40px;

            &--line {
                .news {
                    &__img {
                        width: 160px;
                        height: 160px;
                        margin-right: 24px;
                    }

                    &__text {
                        font-size: 14px;
                        line-height: 168%;
                    }

                    &__name {
                        font-size: 15px;
                        line-height: 132%;
                        margin-bottom: 8px;
                    }

                    &__link {
                        margin-top: 16px;
                        font-size: 10px;
                        line-height: 150%;
                    }
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .news {
        &__img {
            height: 59vw;
        }

        &__item {
            &--line {
                .news {
                    &__img {
                        display: none;
                    }
                }
            }
        }
    }
}