.form {

    &__title {
        font-family: 'Bitter', serif;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 12px;
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        height: 56px;
        width: 56px;
        cursor: pointer;

        .svg-icon {
            position: absolute;
            transition: opacity 0.3s ease;

            &:nth-child(2) {
                opacity: 0;
            }
        }

        &.is-active {
            .svg-icon {
                &:nth-child(1) {
                    opacity: 0;
                }

                &:nth-child(2) {
                    opacity: 1;
                }
            }
        }
    }

    &__link {
        display: flex;
        margin-top: 16px;

        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 153%; 
            letter-spacing: -0.02em;
            text-decoration: underline;
            color: #810D10;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__text {
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.02em;
    }



    &__field {
        position: relative;
        margin-bottom: 24px; 

        &-wrap {
            position: relative;
        }

        &.is-active {
            

            .form {
                &__input {
                    border: transparent;
                    background-color: rgba(237, 230, 230, 0.5);
                }

                &__label {
                    font-size: 12px;
                    line-height: 150%;
                    opacity: 1;
                    bottom: 33px;
                    pointer-events: none;
                    padding-top: 7px;
                    padding-bottom: 0;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__agree {
        display: flex;
        align-items: flex-start;
        margin-top: 20px;
        
        .check {
            &__item {
                margin-right: 10px;
                flex: none;
            }

            &__control {
                margin: 0;
            }
        }
    }

    &__row {
        display: flex;
        align-items: center;
        margin: 0 -12px 24px;

        &:last-child {
            margin-bottom: 0;
        }

        .form {
            &__field {
                width: calc(~"33.3% - 24px");
                margin: 0 12px;
            }
        }

        &--double {
            .form {
                &__field {
                    width: calc(~"50% - 24px");
                    margin: 0 12px;
                }
            }
        }

       
    }

    &__text {
        font-size: 14px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #717171;
        margin-bottom: 24px;
    }



    &__label {
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #717171;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 16px 20px;
        transition: font-size .3s, padding .3s, bottom .3s,top .3s,color .3s,opacity .3s ease;
    }

    &__textarea {
        width: 100%;
        border: 1px solid #F2F2F2;
        font-family: Fira Sans,sans-serif;
        padding: 26px 15px 5px 20px;
        background-color: #FFFFFF;
        height: 56px; 
        font-size: 18px;
        line-height: 100%;
        letter-spacing: -0.02em;
        height: 112px;
        color: #0C0C0C;
        transition: border-color .3s, background-color 0.3s ease;
        &::-webkit-input-placeholder { font-family: 'Fira Sans', sans-serif; font-size: 18px; line-height: 150%; letter-spacing: -0.02em;}
        &::-moz-placeholder          { font-family: 'Fira Sans', sans-serif; font-size: 18px; line-height: 150%; letter-spacing: -0.02em;}
        &:-moz-placeholder           { font-family: 'Fira Sans', sans-serif; font-size: 18px; line-height: 150%; letter-spacing: -0.02em;}
        &:-ms-input-placeholder      { font-family: 'Fira Sans', sans-serif; font-size: 18px; line-height: 150%; letter-spacing: -0.02em;}

    }

    &__input {
        width: 100%;
        border: 1px solid #F2F2F2;
        font-family: Fira Sans,sans-serif;
        padding: 26px 15px 5px 20px;
        background-color: #FFFFFF;
        height: 56px; 
        font-size: 18px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #0C0C0C;
        transition: border-color .3s, background-color 0.3s ease;
        &::-webkit-input-placeholder { font-family: 'Fira Sans', sans-serif; font-size: 18px; line-height: 150%; letter-spacing: -0.02em;}
        &::-moz-placeholder          { font-family: 'Fira Sans', sans-serif; font-size: 18px; line-height: 150%; letter-spacing: -0.02em;}
        &:-moz-placeholder           { font-family: 'Fira Sans', sans-serif; font-size: 18px; line-height: 150%; letter-spacing: -0.02em;}
        &:-ms-input-placeholder      { font-family: 'Fira Sans', sans-serif; font-size: 18px; line-height: 150%; letter-spacing: -0.02em;}

    }

    &--red {
        .form {
            &__textarea {
                border: 0;
                background-color: rgba(237, 230, 230, 0.5);
            }

            &__input {
                border: 0;
                background-color: rgba(237, 230, 230, 0.5);
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .form {
        &__text {
            font-size: 0.97vw;
            margin-bottom: 19px;
        }

        &__field {
            margin-bottom: 1.5vw;
        }

        &__input {
            height: 3.8vw;
        }
    }
}

@media(max-width: 1140px) {
    .form {
        &__title {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 12px;
        }

        &__field {
            margin-bottom: 16px;

            &.is-active .form__label {
                font-size: 12px;
                line-height: 150%;
                opacity: 1;
                bottom: 32px;
                pointer-events: none;
                padding-top: 2px;
                padding-bottom: 0;
            }
        }
        

        &__label {
            font-size: 16px;
            line-height: 150%;
            padding: 12px 20px;
        }
        
        &__control {
            width: 48px;
            height: 48px;
        }

        &__input {
            height: 48px;
            padding: 0 20px;
            padding-top: 15px;
        }
        
        &__text {
            font-size: 12px;
            line-height: 168%;
        }
    }
}

@media(max-width: 960px) {
    .form {
        &--contact {
            max-width: 365px;
        }

        &__row {
            display: block;
            margin: 0;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            .form {
                &__field {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &--double {
                margin: 0;
                margin-bottom: 16px;
    
                &:last-child {
                    margin-bottom: 0;
                }

                .form {
                    &__field {
                        width: 100%;
                        margin: 0;
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .form {
        &--contact {
            max-width: 100%;
        }

    }
}

.invalid-feedback {
    font-size: 13px;
    color: #810d10;
}