.area {
    float: left;
    padding-left: 81px;
    width: calc(~"100% - 316px");

    &__wrap {
        overflow: hidden;
        padding-top: 112px;
        padding-bottom: 136px;

        &--inner {
            padding-top: 0;
        }
    }

    &__text {
        margin-top: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.01em;
        margin-bottom: 32px;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 48px;

        .tag {
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-name {
            display: inline-flex;
            font-weight: 600;
            font-size: 32px;
            line-height: 38px;
            margin-bottom: 24px;
            color: #0C0C0C;
        }

        &-img {
            width: 216px;
            height: 216px;
            border-radius: 50%;
            flex: none;
            margin-right: 31px;

            &-wrap {
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
            }
        }
    }

    &__body {
        float: left;
        width: 316px;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .area {
        width: 74%;
        padding-left: 35px;

        &__text {
            font-size: 1.2vw;
        }

        &__item {
            margin-bottom: 3.5vw;
            
            &-name {
                font-size: 2.3vw;
                margin-bottom: 10px;
            }

            &-img {
                width: 15vw;
                height: 15vw;
                margin-right: 2vw;
            }
        }

        &__body {
            width: 26%;
        }
    }
}

@media(max-width: 1140px) {
    .area {
        width: calc(~"100% - 250px");
        padding-left: 53px;

        &__body {
            width: 250px;
        }

        &__wrap {
            padding-top: 60px;
            padding-bottom: 73px;
        }

        &__text {
            margin-top: 16px;
            font-size: 16px;
            line-height: 150%;  
            margin-bottom: 24px;
        }

        &__item {
            margin-bottom: 36px;

            &-img {
                width: 130px;
                height: 130px;
                margin-right: 16px;
            }

            &-name {
                font-size: 22px;
                line-height: 26px;
                margin-bottom: 16px;
            }
        }
    }
}

@media(max-width: 960px) {
    .area {
        float: none;
        padding: 0;
        width: 100%;

        &__wrap {
            padding-top: 50px;
            display: block;
        }

        &__body {
            position: relative !important;
            top: initial !important;
            width: 100% !important;
            width: 100%;
            float: none;
            padding-bottom: 50px;

            &~div {
                position: relative !important;
                top: initial !important;
                width: 100% !important;
                height: auto !important;
            }
        }
    }
}

@media(max-width: 640px) {
    .area {
        &__body {
            text-align: center;
        }

        &__wrap {
            padding-bottom: 50px;
        }

        &__item {
            flex-direction: column;
            text-align: center;

            .tag {
                &__wrap {
                    justify-content: center;
                }
            }

            &-name {
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 12px;
            }

            &-img {
                width: 100px;
                height: 100px;
                margin-bottom: 14px;
            }
        }
        
    }
}