.dropmenu {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 8;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.19) 100%);
    opacity: 0;
    pointer-events:  none;
    transition: opacity 0.3s ease;
    

    &>ul {
        display: flex;
        padding: 0;
        margin: 0;
        width: calc(~"100% + 1px");
        margin-right: -1px;

        &>li {
            width: 20%;
            list-style: none;
            border-right: 1px solid #fff;

            &>a {
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                padding: 32px;
                height: 400px;
                position: relative;
                background-position: center;
                background-size: cover;

                span {
                    display: block;
                    height: 48px;
                    font-family: 'Bitter', serif;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #FFFFFF;
                    position: relative;
                    max-width: 80%;
                }

                &::before {
                    content:" ";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    background: linear-gradient(360deg, rgba(0, 0, 0, 0.74) 0%, rgba(0, 0, 0, 0) 61.68%);
                }
            }
        }
    }


    &__wrap {
        width: 315px;
        padding: 8px 15px;
        position: relative;
        z-index: 2;
        background-color: #FFFFFF;
        box-shadow: 8px 8px 0px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
       
        a {
            display: inline-flex;
            font-weight: 600;
            font-size: 18px;
            line-height: 160%;
            color: #000;
            text-decoration: none;
            padding: 9px 0;

            span {
                border-bottom: 3px solid transparent;
                transition: border-color 0.3s ease;
            }
        } 
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .dropmenu {
        &__wrap {
            a {
                font-size: 1.3vw;
                padding: 0.6vw 0;
            }
        }
    }
}

@media(min-width: 960px) { 
    .dropmenu {
        &__wrap {
            a {
                &:hover {
                    color: #fff;

                    span {
                        border-color: #fff;
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .dropmenu {
        position: relative;
        opacity: 1;
        pointer-events: auto;
        display: none;
       

        &__wrap {
            background-color: transparent;
            padding: 0;
            padding-top: 15px;
            width: 100%;
            box-shadow: none;

            a {
                font-weight: 300;
                font-size: 14px;
                line-height: 160%;
                color: #fff;
                padding: 3px 0;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}