.subscription {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    padding: 44px 48px 48px;

    &__control {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        line-height: 168%;
        color: @red;
        letter-spacing: -0.02em;

        &-icon {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 8px;
        }
        
        .svg-icon {

            use {
                fill: @red;
            }
            
            &--stroke {
                use {
                    fill: transparent;
                    stroke: @red;
                }
            }

        }
    }

    &__name {
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.02em;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
        
        span {
            font-size: 18px;
            line-height: 150%;
            letter-spacing: -0.02em;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;  
        margin-bottom: 16px;

        svg {
            margin-right: 11px;
            flex: none;
            display: block;
        }
    }

    p {
        margin-bottom: 32px;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .subscription {
        padding: 29px 40px;

        &__name {
            font-size: 1.2vw;
        }

        &__control {
            span {
                font-size: 1vw;
            }
   
            &-icon {
                margin-left: 3px;
            }
        }

        p {
            margin-bottom: 25px;
        }

        &__top {
            font-size: 1.65vw;
            margin-bottom: 12px;

            svg {
                width: 1.8vw;
                height: auto;
            }
        }
    }
}

@media(max-width: 1140px) { 
    .subscription {
        padding: 24px 30px;

        p {
            font-size: 14px;
            line-height: 153%;
            margin-bottom: 24px;
        }

        &__name {
            font-size: 16px;
            line-height: 150%;
        }

        &__control {
            span {
                font-size: 14px;
                line-height: 168%;
            }
        }

        &__top {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;

            svg {
                margin-right: 8px;
            }
        }
    }
}

@media(max-width: 960px) {
    .subscription {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }
}