.radio {
    display: flex;
    flex-direction: column;

    &__row {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -12px;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        margin-right: 24px;
        margin-bottom: 12px;
        position: relative;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
            opacity: 0;

            &:checked {
                &~.radio {
                    &__control {

                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 6px;
        border-radius: 50%;
        flex: none;
        border: 2px solid #EDE6E6;

        &::before {
            content:" ";
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: @light-red;
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.3s ease;
        }
    }

    &__label {
        font-weight: normal;
        font-size: 16px;
        line-height: 153%; 
        letter-spacing: -0.02em;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .radio {
        &__name {
            font-size: 1.1vw;
        }

        &__item {
            margin-right: 17px;
        }

        &__control {
            width: 20px;
            height: 20px;
            margin-right: 5px;

            &::before {
                width: 10px;
                height: 10px;
            }
        }
    }
}

@media(max-width: 1140px) { 
    .radio {
        &__name {
            font-size: 14px;
            line-height: 1;
        }
    }
}

@media(max-width: 960px) {
    .radio {
        &__row {
            flex-direction: column;
            margin-bottom: 0;
        }

        &__item {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}