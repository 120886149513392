.partner {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #E0E0E0;  

    &__item {
        display: inline-flex;
        align-items: center;
        width: 33.3%;
        padding: 0px 20px 0px 0px;
        border: 1px solid #E0E0E0;
        border-right: 0;
        border-bottom: 0;
        color: inherit;
        text-decoration: none;

        &:last-child {
            border-right: 1px solid #E0E0E0;  
        }

        &:nth-child(3n+3) {
            border-right: 1px solid #E0E0E0;  
        }
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        flex: none;
        padding: 10px;
        filter: grayscale(100%); 
        transition: filter 0.3s ease;
    }


    &__type {
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 4px;
        letter-spacing: 0.1em;
        opacity: 0.5;
        margin-bottom: 4px;
        font-family: Galderglynn1884Cd;
    }

    &__name {
        font-weight: 600;
        font-size: 17px;
        line-height: 132%;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .partner {
        &__img {
            width: 8.5vw;
            height: 8.5vw;
        }

        &__type {
            font-size: 1.05vw;
        }

        &__name {
            font-size: 1.2vw;
        }
    }
}

@media(min-width: 960px) {
    .partner {
        &__item {
            &:hover {
                .partner {
                    &__img {
                        filter: grayscale(0%); 
                    }
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .partner {
        border-bottom: 0;

        &__item {
            width: 50%;
            min-height: 98px;
            border-right: 1px solid #E0E0E0 !important;
            border-bottom: 1px solid #E0E0E0 !important;
            margin-right: -1px;
            margin-bottom: -1px;
            padding: 0 10px 0 0;

           /* &:last-child {
                border-right: 1px solid #E0E0E0;  
            }
    
            &:nth-child(3n+3) {
               border: 0; 
            }*/
        }

        &__img {
            width: 80px;
            height: 80px;

            img {
                max-width: 80%;
                height: auto;
            }
        }

        &__name {
            font-size: 14px;
        }

        &__type {
            font-size: 12px;
        }
    }
}

@media(max-width: 640px) {
    .partner {

        &__item {
            width: 100%;
            min-height: 80px;
        }
    }
}

@media(max-width: 480px) {
    .partner {

        &__item {
            width: 100%;
            min-height: 94px;
        }
    }
}