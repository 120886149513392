.info-block {
    padding-bottom: 78px;

    &__img {
        width: 608px;
        height: 351px;
        flex: none;
        background-size: cover;
        background-position: center;
    }

    &__text {
        font-size: 18px;
        line-height: 150%;
        font-family: 'Bitter', serif;
    }

    &__name {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px; 
        font-family: 'Bitter', serif;
        margin-bottom: 20px;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 140px;

        &:last-child {
            margin-bottom: 0;
        }

        &:nth-child(odd) {
            .info-block {
                &__img {
                    order: 1;
                }

                &__body {
                    order: 2;
                    padding-left: 119px;
                }
            }
        }

        &:nth-child(even) {
            .info-block {
                &__img {
                    order: 2;
                }

                &__body {
                    padding-right: 120px;
                    order: 1;
                }
                
            }
        }
    }
}

@media(max-width: 960px) {
    .info-block {
        padding-bottom: 0;

        &__name {
            font-size: 18px;
            line-height: 150%;
        }

        &__img {
            width: calc(~"100% + 80px");
            margin-left: -40px;
            margin-right: -40px;
            height: 42vw;
        }

        &__body {
            margin-bottom: 24px;
        }

        &__text {
            font-size: 15px;
            line-height: 150%;
        }

        &__item {
            flex-direction: column;
            text-align: center;
            margin-bottom: 60px;

            &:nth-child(odd) {
                .info-block { 
                    &__img {
                        order: 2;
                    }
    
                    &__body {
                        order: 1;
                        padding-left: 0;
                    }
                }
                
            }

            &:nth-child(even) {
                .info-block { 
                    &__img {
                        order: 2;
                    }
    
                    &__body {
                        order: 1;
                        padding-right: 0;
                    }
                }
               
            }
        }
    }
}