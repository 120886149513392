.client {
    &__slider {
        .slick-slide {
            padding: 0 12px;
            opacity: 0.2;
            transition: opacity 0.3s ease;

            &.slick-active {
                opacity: 1;
            }
        }

        .slick-list {
            overflow: visible;
            margin: 0 -12px;
        }

        &.slider-common {
            .slick-arrow {
                top: 50%;
                transform: translateY(-50%);

                &.slick-prev {
                    left: -33px;
                }

                &.slick-next {
                    right: -36px;
                }
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
        height: 144px;
        width: 100%;
    }
}

@media(max-width: 1141px) {
    .client {
        &__item {
            padding: 15px;
            height: 113px;
        }
    }
}

@media(max-width: 960px) {
    .client {
        &__item {
            height: 93px;

            img {
                max-width: 80%;
                max-height: 80%;
            }
        }
        &__slider {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px -40px;

            div {
                width: calc(~"33.3% - 24px");
                margin: 0 12px 40px;
            }

            
        }
    }
}

@media(max-width: 640px) {
    .client {
        &__slider {
            &>div {
                width: calc(~"50% - 24px");
            }  
        }
    }
}