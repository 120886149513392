.aside-info {
    h5 {
        margin-bottom: 40px;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .aside-info {
        h5 {
            margin-bottom: 2.6vw;
        }
    }
}