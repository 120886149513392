.quickly {
    display: flex;
    align-items: flex-start;

    &__main {
        width: 455px;
        flex: none;
    }

    &__textarea {
        margin-bottom: 20px;

        textarea {
            border: 0;
            width: 100%;
            height: 92px;
            padding: 14px 0px 14px 20px;
            font-size: 16px;
            line-height: 153%;
            font-family: 'Fira Sans', sans-serif;
            letter-spacing: -0.02em;
            background: rgba(237, 230, 230, 0.5);
            color: #0C0C0C;

            &::-webkit-input-placeholder { font-family: 'Fira Sans', sans-serif; color: #0C0C0C; opacity: 0.5;}
            &::-moz-placeholder          { font-family: 'Fira Sans', sans-serif; color: #0C0C0C; opacity: 0.5;}
            &:-moz-placeholder           { font-family: 'Fira Sans', sans-serif; color: #0C0C0C; opacity: 0.5;}
            &:-ms-input-placeholder       { font-family: 'Fira Sans', sans-serif; color: #0C0C0C; opacity: 0.5;}
        }
    }

    &__bottom {
        display: flex;
        align-items: center;

        .btn {
            margin-right: 12px;
        }
    }

    &__section {
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 20px;
        }
    }

    .radio {
        &__item {
            margin-right: 0;
            margin-bottom: 20px;
        }

        &__control {
            margin-right: 6px;
        }

        &__label {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
        }
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 340px;
        background-color: #F2F2F2;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__info {
        padding-left: 40px;

        p {
            font-size: 14px;
            line-height: 168%;
            letter-spacing: -0.02em;
            max-width: 90%;
        }
    }
}

@media(max-width: 960px) { 
    .quickly {
        display: block;

        &__main {
            width: 100%;
            margin-bottom: 25px;
        }

        &__img {
            height: 49vw;
        }

        &__info {
            padding-left: 0;
        }
    }
}