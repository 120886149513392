.popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    background-color: fade(#1F2F58, 70);
    z-index: 2100;
    opacity: 0;
    overflow-y: auto;
    padding: 15px;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &__info {
        font-size: 14px;
        margin-top: 6px;

        svg {
            width: 7px;
            height: auto;
            margin: 0 5px 1px 0;
            display: inline-block;
        }
    }


    .form {
        margin-top: 32px;

        &__input {
            background-color: rgba(237, 230, 230, 0.5);
        }

        &__textarea {
            background-color: rgba(237, 230, 230, 0.5);
        }

        &__btn {
            margin-top: 32px;

            .btn {
                width: 100%;
            }
        }
    }

    .form-ok {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        &__icon {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    &.is-open {
        opacity: 1;
        pointer-events: auto;
    }

    &__close {
        right: 37px;
        top: 37px;
        position: absolute;
        cursor: pointer;

        .svg-icon {
            use {
                fill: #B5B5B5;
            }
        }
    }

    &__container {
        position: relative;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        width: 591px;
        position: relative;
        max-height: 100%;
        padding: 81px 72px;
        background-color: #FFFFFF;
        box-shadow: 0px 3px 7px rgba(51, 51, 51, 0.15);
        transition: transform 0.3s ease;


        &.is-ok {
            transform: translateY(-100vh);
        }
    }

    &__name {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 10px;
        font-family: 'Bitter', serif;

        &-subtitle {
            font-weight: 600;
            font-size: 22px;
            line-height: 120%;
            margin-bottom: 4px;
        }

        &-small {
            font-weight: 600;
            font-size: 30px;
            line-height: 110%;
            margin-bottom: 24px;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 153%;
        letter-spacing: -0.02em;
    }


    &__top {
        transition: opacity 0.3s ease;
    }


    &__body {
        transition: opacity 0.3s ease;
    }


   &--card {
        .tab {
            &__container {
                padding: 25px 0 0;
            }

            &__content {
                width: 100%;
            }
        }

       .popup {
           
            &__wrap {
                width: 933px;
                padding: 60px 56px 79px 60px;
            }

            &__close {
                right: 58px;
            }
        }
   }
}


@media(max-width: 960px) {
    .popup {
        &--card {
            .popup {
                &__wrap {
                    padding: 24px;
                    width: 95%;
                    overflow-y: auto;
                }

                &__close {
                    right: 29px;
                    top: 20px;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .popup {
        &__wrap {
            width: 100%;
            max-height: 100%;
            padding: 34px 20px;
        }

        

        .form {
            margin-top: 10px;

            &__agree {
                font-size: 13px;
                line-height: 148%;
            }
        }

        &__close {
            right: 17px;
            top: 17px;
        }

        &__name {
            font-size: 25px;
            line-height: 27px;
        }
    }
}