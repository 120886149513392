.back {
    display: inline-flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 168%;
    letter-spacing: -0.02em;
    color: #B5B5B5;
    margin-bottom: 0;
    text-transform: initial;

    .svg-icon {
        margin-right: 12px;

        use {
            fill: transparent;
            stroke: #B5B5B5;
        }
    }
}

@media(min-width: 960px) {
    .back {
        &:hover {
            color: #810d10;

            .svg-icon {      
                use {
                    stroke: #810d10;
                }
            }
            
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) { 
    .back {
        .svg-icon {
            width: 1vw;
            height: auto;
            margin-right: 1vw;
        }

        span {
            font-size: 1.4vw;
        }
    }
}

@media(min-width: 960px) {
    .back {
        &:hover {
            span {
                &::before {
                    height: 4px;
                }
               
            }
        }
    }
}