.video-block {
    display: flex;
    align-items: center;
    margin: 80px 0;

    &__title {
        padding-right: 56px;
        font-weight: bold;
        font-size: 25px;
        line-height: 150%;
        
    }

    &__review {
        width: 357px;
        flex: none;

        .review {
            &__item {
                display: block;
                width: 100%;
                margin-left: 0;
            }
        }
    }
}

@media(max-width: 960px) {
    .video-block {
        margin-top: 4px;
        margin-bottom: 36px;
        display: block;

        &__title {
            font-size: 18px;
            line-height: 150%;
            margin-bottom: 24px;
            padding: 0;
        }

        &__review {
            width: 100%;

            .review {
                &__item {
                    width: 100%;
                    display: block;
                    margin: 0;
                }

                &__img {
                    height: 50vw;
                    margin-bottom: 0;
                }
            }
        }
    }
}