.r-slide {
    display: flex;
    flex-direction: column;

    &__img {
        margin-bottom: 14px;
    }

    &__item {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 19px;
        position: relative;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            cursor: pointer;
            opacity: 0;

            &:checked {
                &~.r-slide {
                    &__control {
                        background-color: @red;
                        &::before {
                            left: 18px;
                        }
                    }
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
        height: 18px;   
        padding-top: 3px;
        padding-left: 2px;
        flex: none;
        background-color: #B5B5B5;
        border-radius: 50px;
        transition: background-color 0.3s ease;

        &-wrap {
            display: inline-flex;
            position: relative;
            order: 2;
        }

        &::before {
            content:" ";
            left: 2px;
            top: 2px;
            position: absolute;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: #fff;
            transition: left 0.3s ease;
        }

    }

    &__label {
        order: 1;
        font-weight: 600;
        font-size: 16px;
        line-height: 153%; 
        letter-spacing: -0.01em;
    }

    &--light {
        .check {
            &__label {
                letter-spacing: -0.02em;
                font-weight: normal;
            }
        }
    }

    &--img {
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        .r-slide {
            &__item {
                display: flex;
                flex-direction: column;
                padding-right: 25px;
                align-items: flex-start;
                width: 33.3%;
            }

            &__body {
                display: inline-flex;
                flex-direction: column;
                align-items: flex-start;

                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    cursor: pointer;
                    opacity: 0;
        
                    &:checked {
                        &~.r-slide {
                            &__body {
                                &-wrap { 

                                    .r-slide {
                                        &__control {
                                            background-color: @red;
                                            &::before {
                                                left: 18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &__label {
                font-weight: normal;
            }

            &__control {
                position: relative;
                margin-right: 7px;

                &-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
            
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .r-slide {
        &__control {
            width: 27px;
            height: 14px;

            &::before {
                width: 10px;
                height: 10px;
            }
        }

        &--img {
            .r-slide__body input:checked~.r-slide__body-wrap .r-slide__control:before {
                left: 15px;
            }
            
            .r-slide {
                &__label {
                    font-size: 1.1vw;
                }

                &__img {
                    margin-bottom: 9px;

                    img {
                        width: 7vw;
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .r-slide {
        &__control {
            width: 26px;
            height: 14px;

            &::before {
                width: 10px;
                height: 10px;
            }
        }

        &__img {
            height: 52px;
            margin-bottom: 9px;

            img {
                max-height: 100%;
            }
        }

        &--img {
            .r-slide__body input:checked~.r-slide__body-wrap .r-slide__control:before {
                left: 15px;
            }

            .r-slide {
                &__label {
                    font-size: 14px;
                    line-height: 153%;
                }

                &__control {
                    &-wrap {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .r-slide {
        &--img {
            .r-slide__body input:checked~.r-slide__body-wrap .r-slide__control:before {
                left: 18px;
            }
            .r-slide {
                &__item {
                    padding-right: 0;
                    justify-content: center;
                    align-items: center;
                }

                &__label {
                    font-size: 12px;
                    line-height: 168%;
                }

                &__control {
                    margin-right: 0;
                    margin-top: 6px;
                    order: 2;

                    &-wrap {
                        justify-content: center;
                        flex-direction: column;
                    }
                }
            }
        }

        &__img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 49px;
            margin-bottom: 8px;
        }

        &__control {
            width: 34px;
            height: 18px;

            &::before {
                width: 14px;
                height: 14px;
            }
        }
    }
}