.production-slider {
    display: none;
}


@media(max-width: 960px) {
    .production-slider {
        display: block;
        position: relative;
        

        &__top {
            position: absolute;
            font-family: Bender;
            letter-spacing: 0.13em;
            text-transform: uppercase;
            color: #FFFFFF;
            font-weight: bold;
            padding: 30px 32px 0;
            font-size: 12px;
            line-height: 150%;
            z-index: 5;

            span {
                opacity: 0.6;

                span {
                    opacity: 1;
                }
            }
        }

        .slick-arrow {
            position: absolute;
            z-index: 5;
            background-color: transparent;
            padding: 0;
            border: 0;
            top: 0;
            width: 66px;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.slick-next {
                right: 0;
            }

            &.slick-prev {
                right: 66px;
            }

            .svg-icon {
                use {
                    fill: transparent;
                    stroke: #fff;
                }
            }
        }

        .slick-current {
            .slide-block {
                &__center {
                    opacity: 1;
                }
            }
        }


        &__item {
            display: flex;
            align-items: center;
            width: calc(~"100vw - 66px");
            height: 100vh;
            position: relative;
            padding: 0 32px;

            &::before {
                content:" ";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.51) 0%, rgba(0, 0, 0, 0) 100%);
            }
        }
    }
}

@media(max-width: 480px) {
    .production-slider {

        .slick-arrow {
            width: 56px;
            height: 72px;

            &.slick-prev {
                right: 56px;
            }
        }

        &__item {
            padding-left: 24px;
            padding-right: 24px;
            width: calc(~"100vw - 56px");
        }
    }
}