.tab-info {
    margin-top: 55px;

    &__top {
        display: flex;
    }

    &__container {
        position: relative;
        z-index: 2;
    }

    &__block {
        display: none;

        background: #FFFFFF;

        form {
            display: block;width: 100%;
        }

        &.is-active {
            display: flex;
        }

        &-form {
            padding: 45px 48px 51px 48px;
        }
    }

    &__link {
        cursor: pointer;
        flex: none;
        display: flex;
        background-color: #E6E6E6;
        padding: 22px 18px 22px 18px;
        position: relative;
        transition: background-color 0.3s ease;

        .stock-icon  {
            position: absolute;
            right: 35px;
            top: -7px;
        }

        &.is-active {
            background-color: #fff;
        }

        &:last-child {
            flex-grow: 1;
        }

        &-icon {
            display: flex;
            align-items: center;
            margin-right: 12px;
            flex: none;

            svg {
                display: block;
            }
        }

        &-name {
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
        }

        &-text {
            font-size: 14px;
            line-height: 132%;  
            letter-spacing: -0.02em;
        }
    }
}
.stock-icon {
   
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 90%;
    letter-spacing: -0.01em;
    border-radius: 50%;
    color: #FFFFFF;
    text-align: center; 


    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        width: 105px;
        height: 105px;
        border-radius: 50%;
        background: #42951B;
    }

    &::before {
        content:" ";
        position: absolute;
        background-color: rgba(66, 149, 27, 0.16);
        width: 137px;
        height: 137px;
        border-radius: 50%;
    }


    &__wrap {
        div {
            margin-top: 5px;
            font-size: 22px;
            line-height: 90%;
    
            span {
                display: inline-flex;
                margin-left: 2px;
                opacity: 0.5;
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .stock-icon {
        &::before {
            width: 9vw;
            height: 9vw;
        }

        &__container {
            width: 7vw;
            height: 7vw;
        }

        &__wrap {
            div {
                font-size: 1.45vw;
            }
        }
    }

    .tab-info {
        margin-top: 20px;

        &__block {
            &-form {
                padding: 33px 35px 36px;
            }
        }

        &__link {
            padding: 15px 14px;

            &-text {
                font-size: 0.9vw;
            }

            &-icon {
                margin-right: 10px;
            }

            &-name {
                font-size: 1.3vw;
            }

            img {
                width: 31px;
            }

            .stock-icon {
                font-size: 1vw;
            }
        }
    }
}


@media(max-width: 1140px) {
    .tab-info {
        margin-top: 45px;

        &__block { 
            &-form {
                padding: 24px 0;
            }
        }

        &__link {
            padding: 6px 8px 8px;
            width: 50%;
            flex-direction: column;
            align-items: center;
            text-align: center;
            flex: none;

            &-icon {
                img {
                    width: 33px;
                }
            }

            .stock-icon {
                right: 11px;
                top: -28px;
            }

            &-text {
                font-size: 12px;
                line-height: 100%;
            }

            &-name {
                font-size: 15px;
                line-height: 100%;
                margin-bottom: 2px;
            }

            &-icon {
                margin-right: 0;
                margin-bottom: 4px;

                svg {
                    display: block;
                    width: 30px;
                    height: auto;
                }
            }
        }
    }

    .stock-icon {
        font-weight: 600;
        font-size: 12.4138px;
        line-height: 90%;
        letter-spacing: -0.01em;

        &::before {
            width: 99px;
            height: 99px;
        }

        &__container {
            width: 78px;
            height: 78px;
        }

        &__wrap {
            div {
                font-weight: 600;
                font-size: 16.1379px;
                line-height: 90%;
                letter-spacing: -0.01em;
            }
        }
    }
}

@media(max-width: 960px) {
    .tab-info {
        margin-top: 40px;

        &__block {  
            &-form {
                padding: 32px 32px 36px;
            }
        }

    }
}

@media(max-width: 480px) { 
    .tab-info {
        &__link {
            .stock-icon {
                right: 5px;
                top: -32px;
            }
        }
    }
    .stock-icon {
        width: 63px;
        height: 63px;
        font-size: 9px;
        line-height: 90%;
        outline: 10px solid rgba(66,149,27,.16);

        &__wrap {
            div {
                font-size: 13.2px;
                line-height: 90%;
            }
        }
    }
}
