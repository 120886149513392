@import "node_modules/bootstrap/less/variables.less";
@import "node_modules/bootstrap/less/mixins/grid-framework.less";
@import "node_modules/bootstrap/less/mixins/grid.less";
@import "node_modules/bootstrap/less/grid.less";
@import "node_modules/bootstrap/less/mixins/responsive-visibility.less";
@import "node_modules/bootstrap/less/responsive-utilities.less";
@import "node_modules/bootstrap/less/mixins/clearfix.less";
@import "partials/normalize.less";
@import "partials/variable.less";
@import "partials/default.less";
@import "partials/font.less";


@import "blocks/address.less";
@import "blocks/area.less";
@import "blocks/aside-info.less";
@import "blocks/bread.less";
@import "blocks/btn.less";
@import "blocks/card.less";
@import "blocks/case.less";
@import "blocks/chapter.less";
@import "blocks/forgot.less";

@import "blocks/catalog.less";
@import "blocks/check.less";
@import "blocks/radio.less";
@import "blocks/hamburger.less";
@import "blocks/header.less";
@import "blocks/heading.less";
@import "blocks/filter.less";
@import "blocks/filter-top.less";
@import "blocks/faq.less";
@import "blocks/form.less";
@import "blocks/lang.less";
@import "blocks/menu-footer.less";
@import "blocks/menu-top.less";
@import "blocks/menu.less";
@import "blocks/social.less";
@import "blocks/user.less";
@import "blocks/footer.less";
@import "blocks/wrapper.less";
@import "blocks/top-page.less";
@import "blocks/tag.less";
@import "blocks/info-block.less";
@import "blocks/warning.less";
@import "blocks/service.less";
@import "blocks/section.less";
@import "blocks/step.less";
@import "blocks/mobile-menu.less";
@import "blocks/form-big.less";
@import "blocks/select.less";
@import "blocks/video.less";
@import "blocks/tech.less";
@import "blocks/ldata.less";
@import "blocks/prod.less";
@import "blocks/tab.less";
@import "blocks/range.less";
@import "blocks/r-slide.less";
@import "blocks/pagination.less";
@import "blocks/page.less";
@import "blocks/order-item.less";
@import "blocks/slider-common.less";
@import "blocks/slick-for.less";
@import "blocks/total.less";
@import "blocks/mainadv.less";
@import "blocks/order-info.less";
@import "blocks/text-block.less";
@import "blocks/client.less";
@import "blocks/popup.less";
@import "blocks/quickly.less";
@import "blocks/doc.less";
@import "blocks/subscription.less";
@import "blocks/history.less";
@import "blocks/dropmenu.less";
@import "blocks/contact.less";
@import "blocks/main-block.less";
@import "blocks/people.less";
@import "blocks/advantage.less";
@import "blocks/tab-info.less";
@import "blocks/map.less";
@import "blocks/price-block.less";
@import "blocks/count.less";
@import "blocks/interes.less";
@import "blocks/mobile-catalog.less";
@import "blocks/notfound.less";















@import "blocks/main-tech.less";
@import "blocks/production-slider.less";

@import "blocks/text-btn.less";

@import "blocks/news.less";
@import "blocks/more.less";


@import "blocks/form-ok.less";
@import "blocks/up.less";
@import "blocks/more.less";
@import "blocks/not-found.less";

@import "blocks/tooltip.less";







@import "blocks/service-banner.less";

@import "blocks/event.less";
@import "blocks/founder.less";
@import "blocks/employee.less";



@import "blocks/result.less";
@import "blocks/question.less";
@import "blocks/back.less";

@import "blocks/contact-main.less";

@import "blocks/pairs.less";
@import "blocks/req.less";

@import "blocks/check-service.less";


@import "blocks/main-block.less";
@import "blocks/section.less";
@import "blocks/banner.less";
@import "blocks/video-block.less";

@import "blocks/partner.less";

@import "blocks/share.less";











@import "blocks/main-info.less";






@import "blocks/detail.less";




@import "blocks/service-item.less";
@import "blocks/question-section.less";
@import "blocks/name-block.less";


.slick-slide {
    height: auto;
}

.popup__text a {
    color: #810d10;
    font-weight: bold;
}

.subscriber_button.is-active+.subscription__control-icon svg:nth-child(2) {
    display: none;
}

.subscriber_button.is-active+.subscription__control-icon svg:nth-child(1) {
    display: block;
}

.subscriber_button~.subscription__control-icon svg:nth-child(1) {
    display: none;
}

.subscription__control {
    cursor: pointer;
}

.tab-info__block form {
    margin-bottom: 0;
}

.price-block__main {
    flex: none;
}

.count {
    margin-left: auto;
    margin-right: auto;
}

.form__field.is-error .form__input {
    border: 1px solid #992B2E;
}

.form__field.is-error .form__label {
    color: #992B2E;
}


.tab__block .form__field.is-active .form__input {
    background-color: #fff;
}