.order-info {
    margin-bottom: 48px;
    letter-spacing: initial;

    &__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 48px;
    }


    &__item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 24px; 

        &:last-child {
            margin-bottom: 0;
        }

        &-type {
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.02em;
            color: #717171;


            span {
                display: block;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-body {
            padding-right: 20px;
        }

        &-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 153%;
            color: #0C0C0C;
            letter-spacing: -0.01em;
            margin-bottom: 9px;
            display: inline-flex;
        }

        &-price {
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            flex: none;
            margin-left: 25px;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .order-info {
        margin-bottom: 3.5vw;
        
        &__title {
            font-size: 1.25vw;
            margin-bottom: 3vw;
        }

        &__item {
            margin-bottom: 1vw;

            &-name {
                font-size: 1.1vw;
            }

            &-price { 
                font-size: 1.3vw;
                line-height: 1.2;
                margin-top: 6px;
            }

            &-type {
                font-size: 1vw;
                line-height: 1;

                span {
                    margin-bottom: 9px;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .order-info {
        margin-bottom: 32px;
        
        &__title {
            font-size: 16px;
            line-height: 19px; 
            margin-bottom: 32px;
        }

        &__item {
            &-name {
                display: inline-flex;
                font-size: 13px;
                line-height: 153%;
                margin-bottom: 7px;
            }

            &-type {
                font-size: 12px;
                line-height: 100%;
            }

            &-price {
                font-size: 16px;
                line-height: 150%;
            }
        }
    }
}