.detail {

    ul {
        margin-top: 24px;
        margin-bottom: 72px;
        padding: 0;

        li {
            font-weight: 300;
            font-size: 17px;
            line-height: 160%;
            position: relative;
            list-style: none;
            margin-bottom: 16px;
            padding-left: 36px;

            &::before {
                content:" ";
                width: 18px;
                height: 18px;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 5px;
                background-color: #1A5DD1;
            }

            &::after {
                content:" ";
                width: 18px;
                height: 18px;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 5px;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url(../images/common/list.svg);
            }

            &:last-child {
                margin-bottom: 0;
            }

        }
    }

    ol {
        margin-top: 24px;
        margin-bottom: 72px;
        counter-reset: myCounter;
        padding: 0;

        li {
            font-weight: 300;
            font-size: 17px;
            line-height: 160%;
            position: relative;
            padding-left: 36px;
            list-style: none;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                content:" ";
                counter-increment: myCounter;
                content:counter(myCounter);
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

    h4 {
        margin: 72px 0 24px;
    }
    
    p {
        
        &:last-child {
            margin: 0;
        }
    }
    
    .big {
        font-weight: 600;
    }


    .mission {
        margin-bottom: 72px;
        margin-top: 72px;
    }

    &__block {
        margin-top: 72px;

        h2 {
            margin-top: 0;
            margin-bottom: 56px;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .detail {
        .big {
            font-size: 1.2vw;
        }

        .mission {
            margin-bottom: 5.1vw;
            margin-top: 5.1vw;
        }

        ol {
            margin-bottom: 5.5vw;

            li {
                font-size: 1.1vw;
                margin-bottom: 1.2vw;
                padding-left: 2.3vw;
            }
        }

        ul {
            margin-bottom: 5.5vw;

            li {
                font-size: 1.1vw;
                margin-bottom: 1.2vw;
                padding-left: 2.3vw;

                &::before {
                    width: 1.2vw;
                    height: 1.2vw;
                    top: 0.2vw;
                }

                &::after {
                    width: 1.2vw;
                    height: 1.2vw;
                    top: 0.2vw;
                    background-size: 60%;
                }
            }
        }

        &__block {
            margin-top: 6.4vw;

            h2 {
                margin-bottom: 5vw;
            }
        }

        h4 {
            font-size: 1.55vw;
            margin-top: 5.5vw;
            margin-bottom: 1.9vw;
        }
    }
}

@media (max-width: 960px) {
    .detail {
        .mission {
            margin-bottom: 40px;
            margin-top: 40px;
        }

        ol {
            li {
                padding-left: 30px;
                font-size: 14px;
            }
        }

        ul {
            li {
                padding-left: 30px;
                font-size: 14px;

                &::before {
                    top: 2px;
                }

                &::after {
                    top: 2px;
                }
            }
        }

        h2 {
            margin-bottom: 32px;
        }

        h4 {
            margin-top: 40px;
            margin-bottom: 16px;
        }

        &__block {
            margin-top: 40px;
        }
    }
}