.prod {
    .slick-slide {
        padding-right: 25px;
    }

    .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 40px;
        position: absolute;
        background-color: transparent;
        border: 0;
        top: -110px;

        .svg-icon {
            use {
                fill: transparent;
                stroke: #fff;
            }
        }

        &.slick-next {
            right: 0;
        }

        &.slick-prev {
            right: 56px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
    }

    &__img {
        width: 104px;
        flex: none;
        height: 80px;
        background-position: center;
        background-size: cover;
        margin-right: 19px;
    }

    &__name {
        font-weight: 500;
        font-size: 16px;
        line-height: 132%; 
        color: #FFFFFF; 
        margin-bottom: 8px;
        transition: color 0.3s ease;
    }

    &__text {
        font-size: 14px;
        line-height: 110%;
        color: @grey_2;
    }

    &--list {
        .prod {
            &__name {
                color: #0C0C0C;
            }

            &__item {
                margin-bottom: 32px;
            }
        }
    }
}

@media(min-width: 960px) {
    .prod {
        &__item {
            &:hover {
                .prod {
                    &__name {
                        color: @grey1;
                    }
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .prod {
        &__img {
            width: 7.2vw;
            height: 5.8vw;
            margin-right: 1.1vw;
        }

        .slick-arrow {
            top: -7.5vw;
        }

        &__text {
            font-size: 1vw;
        }

        &__name {
            font-size: 1.2vw;
            margin-bottom: 5px;
        }

        &--list {
            .prod {
                &__img {
                    width: 7.2vw;
                    height: 5.8vw;
                    margin-right: 1.1vw;
                }

                &__item {
                    margin-bottom: 2vw;
                }

                &__text {
                    font-size: 1vw;
                }
        
                &__name {
                    font-size: 1.2vw;
                    margin-bottom: 5px;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .prod {

        .slick-arrow {
            width: 32px;
            height: 32px;
            top: -82px;

            &.slick-prev {
                right: 32px;
            }

            .svg-icon {
                width: 9px;
                height: auto;
            }
        }

        &__name {
            font-size: 14px;
            line-height: 120%;
        }

        &__text {
            font-size: 12px;
            line-height: 110%;
        }

        &__img {
            width: 80px;
            height: 62px;
            margin-right: 12px;  
        }

        &--list {
            .prod {
                &__item {
                    margin-bottom: 16px;
                }
                
                &__name {
                    font-size: 14px;
                    line-height: 120%;
                }

                &__text {
                    font-size: 12px;
                    line-height: 110%;
                }

                &__img {
                    width: 80px;
                    height: 62px;
                    margin-right: 12px;  
                }
            }
        }

        
    }
}

@media(max-width: 960px) {
    .prod {
        &--slider {
            .slick-arrow {
                display: none !important;
            }

            .slick-list {
                overflow: visible;
                margin-left: -12px;
                margin-right: -40px
            }

            .slick-slide {
                padding-right: 12px;
                padding-left: 12px;
            }
            
            .prod {
                &__item {
                    display: flex;
                    flex-direction: column;
                    width: 136px;
                }

                &__text {
                    display: none;
                }

                &__name {
                    font-size: 13px;
                    line-height: 153%;
                }

                &__img {
                    width: 100%;
                    height: 80px;
                    margin-right: 0;
                    margin-bottom: 8px;
                }
            }
            
        }
        &--aside {
            .slick-list {
                margin: 0 -12px;
            }
        
            .slick-slide {
                padding: 0 12px;
            }

            .prod {
                &__img {
                    width: 100%;
                    height: 185px;
                    margin-bottom: 6px;
                }

                &__text {
                    font-size: 14px;
                    line-height: 153%;
                }

                &__name {
                    font-size: 16px;
                    line-height: 150%;
                    margin-bottom: 0;
                }

                &__item {
                    width: 280px;
                    display: block;
                    margin-bottom: 0;
                }
            }
        }
    }
}

