.contact {
    margin-bottom: 80px;
    max-width: 466px;
    letter-spacing: initial;

    &__map {
        display: none;
    }
    
    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 28px;

        &:last-child {
            margin-bottom: 0;
        }

        div {
            display: inline-flex;
            align-items: center;
            font-weight: 600;
            font-size: 18px;
            line-height: 150%; 
            font-family: 'Bitter', serif; 
            margin-bottom: 4px;

            svg {
                margin-top: -2px;
                margin-left: 6px;

                use {
                    fill: #B5B5B5;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                display: inline-flex;
                align-items: center;
                color: inherit;
            }
        }

        span {
            font-size: 14px;
            line-height: 168%;
            letter-spacing: -0.02em;
            color: @grey_2;
            display: block;
            margin-bottom: 8px;
        }
    }


}

@media (min-width: 1141px) and (max-width: 1440px) {
    .contact {
        &__item {
            span {
                font-size: 1vw;
            }

            div {
                font-size: 1.3vw;
            }
        }
    }
}

@media(max-width: 1140px) {
    .contact {
        margin-bottom: 54px;
        max-width: inherit;
        
        .btn {
            font-size: 12px;
            line-height: 100%;
            padding: 12px 28px;
        }

        &__item {

            margin-bottom: 24px;
        
            div {
                font-size: 16px;
                line-height: 150%;

                svg {
                    width: 17px;
                    height: auto;
                    margin-top: 0;
                }
            }

            span {
                font-size: 12px;
                line-height: 168%;
                margin-bottom: 4px;
            }
        }
    }
}


@media(max-width: 640px) {
    .contact {
        max-width: 100%;

        &__map {
            display: block;
            height: 261px;
            width: calc(~"100% + 64px");
            margin-right: -32px;
            margin-left: -32px;
            margin-bottom: 37px;
        }
    }
}

@media(max-width: 480px) {
    .contact {
        max-width: 100%;
        margin-bottom: 32px;

        .btn {
            width: 100%;
        }

        &__map {
            height: 224px;
            width: calc(~"100% + 48px");
            margin-right: -24px;
            margin-left: -24px;
        }
    }
}