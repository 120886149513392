.range {
    padding-bottom: 8px;
    
    .ui-slider {
        height: 2px;
        background: #EDE6E6;
        border: 0;
    }

    .ui-slider-range {
        height: 2px;
        background: @red;
        border: 0;
        border-radius: 0 !important;
    }

    .ui-slider-horizontal .ui-slider-handle {
        margin-left: -8px;
    }

    .ui-slider-handle {
        top: -6px;
        background-color: @red;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: inline-block;
        border: 0;
    }

    &__info {
        display: flex;
        align-items: center;
        margin: 0 -6px 27px;
    }

    &__item {
        position: relative;
        width: calc(~"50% - 12px");
        margin: 0 6px;

        span {
            position: absolute;
            left: 13px;
            top: 3px;
            font-size: 16px;
            font-family: 'Fira Sans', sans-serif;
            line-height: 153%;
            font-weight: normal;
            letter-spacing: -0.02em;
        }

        input {
            font-size: 16px;
            font-family: 'Fira Sans', sans-serif;
            line-height: 153%;
            letter-spacing: -0.02em;
            height: 32px;
            padding-left: 35px;
            background: rgba(237, 230, 230, 0.5);
            border: 0;
            width: 100%;
        }
    }
}