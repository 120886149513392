.more {
    display: flex;
    align-items: center;
    position: relative;

    &::before {
        content: " ";
        position: absolute;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 64.06%);
        height: 208px;
        left: 0;
        right: 0;
        bottom: -25px;
        pointer-events: none;
    }

    &__link {
        position: relative;
        z-index: 1;
        cursor: pointer;
        font-weight: bold;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.13em;
        text-transform: uppercase;
        color: @green;
    }
}

@media(max-width: 960px) {
    .more {
        &::before {
            display: none;
        }

        &__link {
            width: 100%;
            text-align: center;
            font-weight: bold;
            font-size: 11px;
            line-height: 12px;
            letter-spacing: 0.13em;
            text-transform: uppercase;
            color: @dark;
            padding: 10px;
            background-color: @grey-light;
            border-radius: 30px;
        }
    }
}