.interes {
    &__img {
        width: 100%;
        height: 185px;
        margin-bottom: 14px;
        position: relative;
    }

    &__tag {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        font-weight: bold;
        font-size: 10px;
        line-height: 100%;
        font-family: 'Bitter', serif;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #fff;
        padding: 9px;
        background-color: #42951B;
    }

    &__item {
        color: inherit
    }

    &__name {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        transition: color 0.3s ease;
        margin-bottom: 4px;
        letter-spacing: initial;
    }

    &__text {
        font-size: 16px;
        line-height: 153%;
        transition: color 0.3s ease;
    }

    .slick-list {
        margin: 0 -16px;
        overflow: visible;
    }

    .slick-slide {
        padding: 0 16px;
        transition: opacity 0.3s ease;
        opacity: 0.2;

        &.slick-active {
            opacity: 1;
        }
    }

    .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 40px;
        height: 40px;
        position: absolute;
        background-color: transparent;
        border: 0;
        top: -78px;

        .svg-icon {
            use {
                fill: transparent;
                stroke: #717171;
            }
        }

        &.slick-next {
            right: 0;
        }

        &.slick-prev {
            right: 56px;
        }
    }
}

@media(min-width: 960px) {
    .interes {
        &__item {
            &:hover {
                .interes {
                    &__name {
                        color: @red;
                    }

                    &__text {
                        color: @red;
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .interes {
        &__item {
            width: 280px;
            display: block;
        }

        .slick-list {
            margin: 0 -12px;
        }
    
        .slick-slide {
            padding: 0 12px;
        }
    }
}


@media(max-width: 1140px) {
    .interes {
        &__img {
            margin-bottom: 6px;
        }

        &__text {
            font-size: 14px;
            line-height: 153%;
        }

        &__name {
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 0;
        }
    }
}