.case {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -36px -87px;

    &__img {
        height: 339px;
        background-position: center;
        background-size: cover;
        margin-bottom: 24px;
    }

    &__name {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #0C0C0C;
        margin-bottom: 24px;
        transition: color 0.3s ease;
    }

    &__text {
        color: #0C0C0C;

        div {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                display: inline-block;
                font-weight: bold;
                padding: 2px 8px;
                font-size: 19px;
                line-height: 132%;
                margin-right: 10px;
                color: #fff;
                background-color: @red;
            }
        }

        &--info {
            margin-bottom: 56px;
        }

        &--mobile {
            display: none;
        }
    }

    &__item {
        display: block;
        width: calc(~"50% - 72px");
        margin: 0 36px 87px;
    }

    &__slider {
        .slick-list {
            overflow: visible;
            margin: 0 -15px;
        }

        .slick-slide {
            transition: opacity 0.3s ease;
            opacity: 0.2;

            &.slick-active {
                opacity: 1;
            }
        }

        .slick-slide {
            padding: 0 16px;
        }

        .case {
            &__item {
                width: 100%;
                margin: 0;
            }

            &__img {
                height: 260px;
                margin-bottom: 16px;
            }

            &__name {
                font-size: 18px;
                line-height: 150%;
                margin-bottom: 16px;
            }
        }
    }
}

@media(min-width: 960px) {
    .case {
        &__item {
            &:hover {
                .case {
                    &__name {
                        color: @red;
                    }
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {

    .case {
        margin: 0 -2.5vw -4.3vw;

        &__img {
            margin-bottom: 1.5vw;
            height: 23.5vw;
        }

        &__text {
            div {
                font-size: 1.3vw;

                span {
                    font-size: 1.3vw;
                    padding: 1px 7px;
                }
            }
        }

        &__name {
            font-size: 1.7vw;
            margin-bottom: 1.3vw;
        }



        &__item {
            width: calc(~"50% - 5vw");
            margin: 0 2.5vw 4.3vw;
        }

        &__slider {
            .case {
                &__img {
                    height: 18vw;
                    margin-bottom: 1vw;
                }

                &__name {
                    font-size: 1.3vw;
                    margin-bottom: 1.3vw;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .case {
        margin: 0 -16px -48px;

        &__name {
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 8px;
        }

        &__text {
            div {
                margin-bottom: 6px;
                font-size: 13px;
                line-height: 150%;

                span {
                    margin-right: 6px;
                    font-size: 16px;
                    line-height: 132%;
                }
            }
        }

        &__img {
            height: 25.2vw;
            margin-bottom: 5px;
        }

        &__item {
            display: block;
            width: calc(~"50% - 32px");
            margin: 0 16px 48px;
        }

        &__slider {
            .slick-list {
                margin-left: -8px;
                margin-right: -8px;
            }

            .slick-slide {
                padding: 0 8px;
            }

            .case {
                &__img {
                    height: 190px;
                    margin-bottom: 8px;
                }

                &__item {
                    width: 280px;
                }
            }
        }
    
    }
}

@media(max-width: 960px) {
    .case {
        margin: 0 -12px -36px;

        &__img {
            height: 29.6vw;
        }

        &__slider {

        }

        &__item {
            display: block;
            width: calc(~"50% - 24px");
            margin: 0 12px 36px;
        }
    
        &__text {
            &--mobile {
                display: block;
                margin-bottom: 20px;

                div {
                    margin-bottom: 8px;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .case {
        margin: 0 -12px -32px;

        &__img {
            height: 55.2vw;
        }

        &__item {
            display: block;
            width: calc(~"100% - 24px");
            margin: 0 12px 32px;
        }
    
    }
}

@media(max-width: 480px) {
    .case {
        &__img {
            height: 52.2vw;
        }

    }
}