.text-block {
    display: flex;
    position: relative;
    justify-content: flex-end;

    &__head {
        color: #fff;
        margin-bottom: 49px;
    }

    &__text {
        font-size: 18px;
        line-height: 150%;
        letter-spacing: initial;
    }

    &__img {
        width: 853px;
        flex: none;
        left: -134px;
        top: -237px;    
        position: absolute;
    }

    &__title {
        color: #fff;
    }

    &__body {
        position: relative;
        width: 572px;
        flex: none;
        margin-top: -59px;
        margin-bottom: -38px;
    }

    &__item {
        font-family: 'Bitter', serif;

        color: #fff;
        text-align: center;
        margin-bottom: 32px;
    

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 8px;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .text-block {
        &__img {
            width: 61vw;
            left: -8%;
            top: -247px;
        }

        &__title {
            font-size: 1.7vw;
            margin-bottom: 3px;
        }

        &__text {
            font-size: 1.3vw;
        }

        &__head {
            margin-bottom: 3vw;
        }
        
        &__item {
            margin-bottom: 20px;
        }

        &__body {
            width: 40vw;
            margin-top: -87px;
            margin-bottom: -59px;
        }
    }
}

@media(max-width: 1140px) {
    .text-block {
        &__img {
            left: initial;
            top: -234px;
            width: 615px;
            right: 475px;
        }

        &__item {
            text-align: left;
            margin-bottom: 25px;
        }

        &__title {
            font-size: 18px;
            line-height: 22px;
        }

        &__head {
            margin-bottom: 32px;
        }

        &__text {
            font-size: 15px;
            opacity: 0.6;
        }

        &__body {
            margin-bottom: -97px;
            width: 577px;
            margin-top: -128px;
        }
    }
}

@media(max-width: 960px) {
    .text-block {
        &__img {
            display: none;
        }

        &__head {
            text-align: center;
        }

        &__text {
            text-align: center;
        }

        &__body {
            margin-bottom: -88px;
            width: 100%;
            margin-top: -139px;
            padding: 0 60px;
        }

        &__title {
            text-align: center;
        }
    }
}


@media(max-width: 640px) {
    .text-block {
        &__body {
            padding: 0;
        }
    }
}