.tech {
    display: flex;
    margin-bottom: 38px;

    &__item {
        width: 33.3%;
        padding-right: 25px;
    }

    &__title {
        font-size: 14px;
        line-height: 100%; 
        letter-spacing: -0.02em; 
        margin-bottom: 8px;
    }

    &__size {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .tech {
        margin-bottom: 32px;
        
        &__title {
            font-size: 0.94vw;
            margin-bottom: 0px;
        }

        &__size {
            font-size: 1.2vw;
        }
    }
}

@media(max-width: 1140px) {
    .tech {
        display: block;
        margin-bottom: 32px;

        &__title {
            font-size: 16px;
            line-height: 150%;
            width: 144px;
            margin-right: 8px;
            flex: none;
            color: #717171;
            margin-bottom: 0;
        }

        &__item {
            padding-right: 0;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__size {
            font-family: 'Fira Sans', sans-serif;
            font-size: 16px;
            line-height: 150%;
        }

        &__item {
            width: 100%;
            display: flex;
        }
    }
}

@media(max-width: 960px) {
    .tech {
        &__title {
            font-size: 16px;
            line-height: 150%;
        }

        &__size {
            font-size: 16px;
            line-height: 150%;
        }
    }
}