.bread {
    margin-bottom: 17px;

    ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;

        li {
            list-style: none;
            text-decoration: none;
            display: inline-flex;
            align-items: center;
            margin-right: 14px;
    
            &:last-child {
                margin-right: 0;
            }
    
            a {
                display: inline-flex;
                align-items: center;
                font-size: 14px;
                line-height: 168%;
                letter-spacing: -0.02em;
                color: @grey1;

                .svg-icon {
                    display: block;
                    margin-bottom: 2px;
                    margin-left: 12px;
    
                    use {
                        stroke: @grey1;
                        fill: transparent;
                    }
                }
            }
    
            span {
                display: inline-flex;
                font-size: 14px;
                line-height: 168%;
                letter-spacing: -0.02em;
                color: @grey1;
            }
        }
    }

  
}

@media(max-width: 1140px) {
    .bread {
        ul {
            li {
                display: none;

                &:nth-last-child(2) {
                    display: inline-flex;
                }
                a {
                    font-size: 12px;
                    line-height: 168%;
                    letter-spacing: -0.02em;

                    span {
                        order: 2;
                        margin-left: 4px;
                    }

                    .svg-icon {
                        transform: rotate(180deg);
                        margin-bottom: 0;
                        margin: 0;
                        order: 1;
                    }
                }

                span {
                    font-size: 12px;
                    line-height: 168%;
                    letter-spacing: -0.02em;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .bread {
        margin-bottom: 12px;

        ul {
            &>li {
                display: none;

                a {
                    align-items: center;
                    font-size: 10px;
                    line-height: 160%;

                    svg {
                        display: none;
                        margin-left: 0;
                    }

                    .svg-icon {
                        display: flex;
                        margin-right: 5px;

                        use {
                            stroke: #a6a6a6;
                        }
                    }
                }

                &:nth-last-child(2) {
                    display: flex;
                }
            }
        }
    }
}