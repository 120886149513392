.up {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #1F2F58;
    position: fixed;
    cursor: pointer;
    bottom: 17px;
    right: 17px;
    opacity: 0;
    pointer-events: none; 
    transition: opacity 0.3s ease;
    z-index: 15;
    transition: border-color 0.3s ease;

    svg {
        path {
            fill: #1F2F58;
            transition: fill 0.3s ease;
        }
    }

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }

    &.is-white {
        border-color: #fff;

        svg {
            path {
                fill: #fff;
            }
        }
    }
}

@media(min-width: 960px) {
    .up {
        &:hover {
            opacity: 0.7;
        }
    }
}