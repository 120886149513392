* {
    box-sizing: border-box;
}

.clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .modal-footer:after, .modal-footer:before, .row:after, .row:before {
    content: " ";
    display: table;
}

.clearfix:after, .container-fluid:after, .container:after, .dl-horizontal dd:after, .modal-footer:after, .row:after {
    clear: both;
}

::marker {
    display: none;
}

html, body {
    height: 100%;
}

.bitter {
    font-family: 'Bitter', serif;
}

.wrapper {
    overflow-x: hidden;
}

body  {
    overflow-x: hidden;
    height: auto;
    min-height: 100%;
    color: #0C0C0C;
    font-weight: normal;
    font-size: 16px;
    line-height: 153%;
    font-family: 'Fira Sans', sans-serif;
    overflow-x: hidden;

    &.is-hidden {
        overflow: hidden;
    }

    &.is-filter-open {
        .wrapper__wrap {
            z-index: 8000;
        }
    }
}

.back {

}

p {
    font-size: 16px;
    line-height: 153%;
    letter-spacing: -0.02em;
    margin: 0;
    margin-bottom: 7px;

    a {
        color: @red;
    }
}

@media(max-width: 1140px) {
    p {
        font-size: 14px;
        line-height: 153%;
    }
}


input, a, button, .slick-slide, textarea {
    outline: 0;
}

a {
    transition: color 0.3s ease;
    text-decoration: none;
}

img {
    max-width: 100%;
    display: block;
}

textarea {
    resize: none;
}

.svg-icon {
    use {
        transition: fill 0.3s, stroke 0.3s ease;
    }
}

a {
    transition: color 0.3s ease;
}

.container {
    &::before {
        display: none;
    }

    &::after {
        display: none;
    }
}


@media (min-width: 1246px) {
    .container {
        width: 1246px;

        &--flex {
            display: flex;
            flex-wrap: wrap;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .container {
        width: 100%;
        padding-left: 8vw;
        padding-right: 8vw;
    }
}

@media(max-width: 1140px) {
    .container {
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media(max-width: 640px) {
    .container {
        width: 100%;
        padding-left: 32px;
        padding-right: 32px;
    }
}


@media(max-width: 480px) {
    .container {
        padding-left: 24px;
        padding-right: 24px;
    }
}

.col-1 {
    width: 25%;
}

.col-3 {
    width: 75%;
}



.small-title {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
}

@media(max-width: 960px) {
    .hide-mobile-960 {
        display: none !important;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    p {
        font-size: 1.1vw;
        line-height: 1.53;
    }
}

.mobile-flex {
    display: none;
}

@media(max-width: 960px) {
    .mobile-flex {
        display: flex;
    }
}