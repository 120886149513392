.forgot {
    width: 463px;
    margin: 0 auto;
    max-width: 100%;

    .form {
        &__btn {
            .btn {
                width: 100%;
            }
        }
    }

    .h3 {
        margin-bottom: 32px;
    }
}