.result {
    display: flex;
    margin: 0 -39px;

    &__col {
        width: calc(~"50% - 78px");
        margin: 0 39px;

        &--full {
            width: calc(~"100% - 78px");
        }
    }

    &__bread {

 
        .bread {
            margin-bottom: 0;

            li {
                a {
                    color: #ABABAB;

                    .svg-icon {
                        use {
                            stroke: #ABABAB;
                        }
                    }
                }

                span {
                    color: #ABABAB;
                }
            }
        }
    }

    &__section {
        margin-bottom: 80px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        display: inline-flex;
        font-weight: 600;
        font-size: 19px;
        line-height: 132%;
        color: #1A5DD1;
        text-decoration: none;
        margin-bottom: 8px;
    }

    &__text {
        margin-bottom: 8px;
        font-weight: 300;
        font-size: 15px;
        line-height: 160%;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 32px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        padding-bottom: 4px;
        border-bottom: 1px solid #000;
        margin-bottom: 32px;
    }
}

@media(min-width: 960px) {
    .result {
        &__name {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .result {
        margin: 0 -2vw;

        &__item {
            margin-bottom: 1.8vw;
        }

        &__title {
            font-size: 1.6vw;
            margin-bottom: 2vw;
        }

        &__section {
            margin-bottom: 40px;
        }

        &__text {
            font-size: 1.1vw;
        }

        &__name {
            font-size: 1.3vw;
        }

        &__col {
            width: calc(~"50% - 2vw");
            margin: 0 2vw;

            &--full {
                width: calc(~"100% - 2vw");
            }
        }
    }
}

@media (max-width: 960px) {
    .result {
        margin: 0 -15px;

        &__text {
            font-size: 12px;
        }

        &__col {
            width: calc(~"50% - 30px");
            margin: 0 15px;

            &--full {
                width: calc(~"100% - 30px");
            }
        }

        &__name {
            font-size: 13.0286px;
        }

        &__title {
            font-size: 17px;
            margin-bottom: 17px;
        }
    }
}

@media (max-width: 640px) {
    .result {
        flex-wrap: wrap;

        &__item {
            margin-bottom: 12px;
        }

        &__section {
            margin-bottom: 35px;
        }

        &__col {
            width: calc(~"100% - 30px");
            margin-bottom: 35px;

            &--full {
                width: calc(~"100% - 30px");
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}