.footer {
    padding-top: 79px;
    padding-bottom: 94px;
    background-color: @black;
    height: 488px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    &__address {
        max-width: 384px;

        .mail {
            display: none;
        }

        .policy {
            display: none;
        }
    }

    &__info {
        .logo {
            display: none;
        }
        .phone {
            font-size: 16px;
            line-height: 153%;
            letter-spacing: -0.02em;
            margin-bottom: 28px;
            color: #FFFFFF;

            span {
                display: block;
                margin-bottom: 4px;
            }   

            a {
                font-weight: 600;
                font-size: 30px;
                line-height: 100%;
                color: #FFFFFF;
            }
        }
    }

    &__menu {
        padding-right: 33px;
    }

    &__catalog {
        padding-right: 33px;
    }

    &__top {
        .container {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }
    }
}

.policy {
    margin-top: 28px;
    font-size: 13px;
    line-height: 160%;
    color: #303030;

    div {
        margin-bottom: 4px;
    }

    a {
        text-decoration: underline;
        font-size: 13px;
        line-height: 160%;
        color: #303030;

        &.site {
            display: inline-flex;
            align-items: center;
            text-decoration: none;

            svg {
                margin-left: 6px;
                margin-top: 1px;
            }
        }
    }
}


@media(max-width: 1140px) {
    .footer {
        text-align: center;
        height: 467px;
        padding-bottom: 40px;
        padding-top: 48px;
        
        &__address {
            width: 100%;
            max-width: initial;
            order: 2;

            .policy {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                display: flex;
                
                div {
                    margin-bottom: 1px;
                }
            }

            .mail {
                margin-top: 32px;
                margin-bottom: 32px;
                display: inline-flex;
                text-align: center;
                font-weight: 600;
                font-size: 16px;
                line-height: 100%;
                color: #fff;
                text-decoration: none;
            }
        }

        &__menu {
            display: none;
        }

        .logo {
            display: inline-flex;
            margin-bottom: 32px;
        }

        &__info {
            order: 1;

            .policy {
                display: none;
            }

            .social {
                justify-content: center;

                &__item {
                    margin: 0 4px;
                    width: 40px;
                    height: 40px;
                    background-color: #303030;
                }
            }
            
            .phone {
                a {
                    font-size: 26px;
                    line-height: 100%;
                    margin-bottom: 32px;
                }
               
                span {
                    display: none;
                }
            }
        }

        &__top {
            .container {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
            
        }

        &__catalog {
            display: none;
        }
    }
}

@media(max-width: 960px) {
    .footer {
        height: 515px;
    }
}