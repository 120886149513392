.doc {
    display: inline-flex;

    &__section {
        width: 1008px;
        max-width: 100%;
    }
    
    &__container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px; 
        font-family: 'Bitter', serif;
        margin-bottom: 48px;
    }
    
    &__section {
        margin-bottom: 64px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    &__item {
       width: 33.3%;
       margin-bottom: 32px;
       padding-right: 35px;
    }

    &__icon {
        flex: none;
        margin-right: 14px;
        flex: none;
        opacity: 0.5;
        transition: opacity 0.3s ease;

        svg {
            display: block;
        }
    }

    &__name {
        font-size: 18px;
        line-height: 100%;
        letter-spacing: -0.02em;
        margin-bottom: 4px;
        color: #0C0C0C;
    }

    &__link {
        font-size: 14px;
        line-height: 100%;
        color: @red;
        letter-spacing: -0.02em;
        opacity: 0.5;
        transition: opacity 0.3s ease;
    }
}

@media (min-width: 1140px) and (max-width: 1440px) {
    .doc {
        &__title {
            font-size: 1.7vw;
            margin-bottom: 3vw;
        }

        &__link {
            font-size: 1vw;
        }

        &__name {
            font-size: 1.3vw;
            list-style: 1.2;
        }

        &__section {
            margin-bottom: 2vw;
        }

        &__item {
            margin-bottom: 1.4vw;
        }

        &__icon {
            width: 2.3vw;
            margin-right: 0.8vw;

            svg {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }
}

@media(min-width: 960px) {
    .doc {
        &__item {
            &:hover {
                .doc {
                    &__link {
                        opacity: 1;
                    }

                    &__icon {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .doc {
        &__icon {
            margin-right: 10px;
        }

        &__title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 24px;
        }

        &__container {
            margin-bottom: -16px;
        }

        &__section {
            width: 100%;
            margin-bottom: 40px;
        }

        &__link {
            font-size: 12px;
        }

        &__item {
            margin-bottom: 16px;
        }

        &__name {
            font-size: 16px;
            line-height: 100%;
        }
    }
}

@media (max-width: 960px) {
    .doc {
        &__item {
            width: 50%;
         }
    }
}

@media (max-width: 640px) {
    .doc {
        &__item {
            width: 100%;
         }
    }
}