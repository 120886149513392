.share {
    position: absolute;
    left: 0;
    top: 2px;

    &__title {
        font-size: 14px;
        line-height: 150%;
        font-family: 'Galderglynn1884Cd';
        position: absolute;
        transform: rotate(90deg);
        top: 29px;
        right: -61px;
        letter-spacing: 0.1em;
    }
    
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 58px;
        height: 57px;
        border: 1px solid #000;
        transition: background-color 0.3s ease;
    }

    &__body {
        .ya-share2__list {
            display: flex;
            flex-direction: column;
        }


        .ya-share2__container_size_l .ya-share2__badge .ya-share2__icon {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background: none;
        }

        .ya-share2__container_shape_round .ya-share2__badge {
            border: 1px solid #000;
            border-bottom: 0;
            border-radius: 0;
            width: 58px;
            height: 57px;
        }

        .ya-share2__list.ya-share2__list_direction_horizontal > .ya-share2__item {
            margin: 0;
        }



        .ya-share2__container_size_l .ya-share2__badge .ya-share2__icon::before {
            content: " ";
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            top: 0;
        
            background-position: center;
            transition: opacity 0.3s ease;
            background-repeat: no-repeat;
        }

        .ya-share2__container_size_l .ya-share2__badge .ya-share2__icon::after {
            content: " ";
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            top: 0;
            background-position: center;
            opacity: 0;
            transition: opacity 0.3s ease;
            background-repeat: no-repeat;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_vkontakte .ya-share2__icon::before {
            background-image: url(../images/social/vk-d.png);
            background-size: 24px 14px;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_vkontakte .ya-share2__icon::after {
            background-image: url(../images/social/vk.png);
            background-size: 24px 14px;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_vkontakte .ya-share2__icon {
            transition: background-color 0.3s ease;
        }


        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_facebook .ya-share2__icon::before {
            background-image: url(../images/social/face-d.png);
            background-size: 11px 22px;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_facebook .ya-share2__icon::after {
            background-image: url(../images/social/face.png);
            background-size: 11px 22px;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_facebook .ya-share2__icon {
            transition: background-color 0.3s ease;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_odnoklassniki .ya-share2__icon::before {
            background-image: url(../images/social/odn-d.png);
            background-size: 14px 24px;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_odnoklassniki .ya-share2__icon::after {
            background-image: url(../images/social/odn.png);
            background-size: 14px 24px;
        }


        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_odnoklassniki .ya-share2__icon {
            transition: background-color 0.3s ease;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_telegram .ya-share2__icon::before {
            background-image: url(../images/social/tel-d.png);
            background-size: 21px 17px;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_telegram .ya-share2__icon::after {
            background-image: url(../images/social/tel.png);
            background-size: 21px 17px;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_telegram .ya-share2__icon {
            transition: background-color 0.3s ease;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_whatsapp .ya-share2__icon::before {
            background-image: url(../images/social/what-d.png);
            background-size: 23px 23px;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_whatsapp .ya-share2__icon::after {
            background-image: url(../images/social/what.png);
            background-size: 23px 23px;
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_whatsapp .ya-share2__icon {
            transition: background-color 0.3s ease;
        }
    }
}

@media(min-width: 960px ) {
    .share {
        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_vkontakte .ya-share2__icon {
            &:hover {
                background-color: #2787F5;

                &::before {
                    opacity: 0;
                }
    
                &::after {
                    opacity: 1;
                }
            }

            
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_facebook .ya-share2__icon {
            &:hover {
                background-color: #3C589A;

                &::before {
                    opacity: 0;
                }
    
                &::after {
                    opacity: 1;
                }
            }
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_odnoklassniki .ya-share2__icon {
            &:hover {
                background-color: #FA8433;


                &::before {
                    opacity: 0;
                }
    
                &::after {
                    opacity: 1;
                }
            }
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_telegram .ya-share2__icon {
            &:hover {
                background-color: #249DD0;

                &::before {
                    opacity: 0;
                }
    
                &::after {
                    opacity: 1;
                }
            }
        }

        .ya-share2__container_color-scheme_whiteblack .ya-share2__item_service_whatsapp .ya-share2__icon {
            &:hover {
                background-color: #26D366;

                &::before {
                    opacity: 0;
                }
    
                &::after {
                    opacity: 1;
                }
            }
        }

        &__link {
            &:hover {
                background-color: #000000;

                .svg-icon {
                    use {
                        fill: #fff;
                    }
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .share {
        .ya-share2__container_shape_round .ya-share2__badge {
            width: 4.2vw;
            height: 4.2vw;
        }

        .ya-share2__container_shape_round.ya-share2__container_size_l .ya-share2__badge .ya-share2__icon:not(.ya-share2__icon_messenger-contact):not(.ya-share2__icon_more):not(.ya-share2__icon_copy) {
            background-size: 3vw 3vw;
        }

        &__title {
            font-size: 1vw;
            top: 2vw;
            right: -4.5vw;
        }

        &__link {
            width: 4.2vw;
            height: 4.2vw;

            .svg-icon  {
                width: 2vw;
                height: auto;
            }
        }
    }
}