.mainadv {
    display: flex;
    align-items: center;

    &__body {
        padding-left: 72px;
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex: none;
        width: 574px;
        height: 431px;
        background-position: center;
        background-size: cover;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.2);
            z-index: 1;
        }
    }

    &__play {
        width: 72px;
        height: 72px;
        background-color: @red;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
    }

    &__body {

    }

    &__title {
        font-weight: 600;
        font-size: 36px;
        line-height: 43px;
        margin-bottom: 53px;
    }

    &__list {
        margin-bottom: 48px;

        ol {
            counter-reset: myCounter;
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                position: relative;
                padding-left: 65px;
                font-family: 'Bitter', serif;
                font-weight: 600;
                font-size: 19px;
                line-height: 132%;
                letter-spacing: initial;
                margin-bottom: 39px;

                &:last-child {
                    margin-bottom: 0;
                }

                &::after {
                    content:" ";
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 132%;
                    color: #fff;
                    position: absolute;
                    top: -14px;
                    left: -8px;
                    border-radius: 50%;
                    border: 8px solid rgba(129, 13, 16, 0.1);
                }
                
                &::before {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 132%;
                    color: #fff;
                    position: absolute;
                    top: -6px;
                    left: 0;
                    background-color: @red;
                    border-radius: 50%;
                    counter-increment: myCounter;
                    content: counter(myCounter);
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .mainadv {
        &__img {
            width: 40vw;
            height: 30vw;
        }

        &__list {
            ol {
                li {
                    padding-left: 4.5vw;
                    font-size: 1.33vw;
                    line-height: 1.2;
                    margin-bottom: 2.8vw;

                    &::after {
                        width: 2.8vw;
                        height: 2.8vw;
                    }

                    &::before {
                        width: 2.8vw;
                        height: 2.8vw;
                        font-size: 1.5vw;
                        line-height: 1;
                        padding-top: 1px;
                    }
                }
            }
        }

        &__title {
            font-size: 2.55vw;
            margin-bottom: 3.5vw;
        }

        &__body {
            padding-left: 5vw;
        }
    }
}

@media(max-width: 1140px) {
    .mainadv {
        &__title {
            font-size: 22px;
            line-height: 26px;
            margin-bottom: 39px;
        }
        
        .btn {
            padding: 12px 28px;
        }

        &__body {
            padding-left: 39px;
        }

        &__img {
            width: 413px;
            height: 303px;
        }

        &__list {
            margin-bottom: 32px;
            ol {
                li {
                    padding-left: 50px;
                    font-size: 16px;
                    line-height: 132%;
                    margin-bottom: 34px;

                    &::after {
                        border:  4.64667px solid rgba(129, 13, 16, 0.1);
                        width: 34px;
                        height: 34px;
                        top: -3px;
                        left: -5px;
                    }

                    &::before {
                        width: 34px;
                        height: 34px;
                        font-size: 18px;
                        line-height: 132%;
                    }
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .mainadv {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__play {
            width: 52px;
            height: 52px;

            .svg-icon {
                width: 10px;
                height: auto;
            }
        }

        &__body {
            padding-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center; 
        }
        
        &__list {
            width: 357px;

            ol {
                li {
                    &::before {
                        top: 2px;
                    }
                }
            }
        }

        &__title {
            text-align: center;
        }
        

        &__img {
            order: 2;
            width: 323px;
            height: 220px;
            margin-top: 32px;
        }
    }
}

@media(max-width: 640px) {
    .mainadv {

        &__img {
            margin-top: 40px;
            margin-right: -32px;
            margin-left: -32px;
            width: calc(~"100% + 64px");
            height: 49vw;
        }

        &__list {
            width: 258px;

            ol {
                li {
                    padding-left: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin-bottom: 32px;

                    &::before {
                        position: relative;
                        top: initial;
                        left: initial;
                        margin-bottom: 12px;
                    }

                    &::after {
                        top: -5px;
                        left: auto;
                    }
                }
            }
        }
    }
}


@media(max-width: 480px) {
    .mainadv {
        &__img {
            margin-top: 40px;
            margin-right: -24px;
            margin-left: -24px;
            width: calc(~"100% + 48px");
            height: 49vw;
            height: 65vw;
        }
    }
}