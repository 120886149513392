.catalog {
    margin: 0 -16px;
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: block;
        margin: 0 16px 31px;
        width: calc(~"33.3% - 32px");
    }

    &__status {
        position: absolute;
        top: 0;
        right: 0;
        font-weight: bold;
        font-family: 'Bitter', serif;
        font-size: 10px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 9px;
        background: #42951B;
    }

    &__name {
        font-weight: 600;
        font-family: 'Bitter', serif;
        font-size: 18px;
        line-height: 150%;
        color: @black;
        margin-bottom: 4px;
        transition: color 0.3s ease;
    }

    &__price {
        font-size: 16px;
        line-height: 153%;
        letter-spacing: -0.02em;
        color: #333333;
        transition: color 0.3s ease;
    }

    &__img {
        display: flex;
        align-items: flex-start;
        position: relative;
        justify-content: flex-end;
        overflow: hidden;
        width: 100%;
        height: 185px;
        background-color: #000;
        margin-bottom: 14px;

        &-wrap {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            transition: transform 0.3s ease;
        }
    }
}

@media(min-width: 960px) {
    .catalog {
        &__item {
            &:hover {
                .catalog {
                    &__name {
                        color: @red;
                    }

                    &__img {
                        &-wrap {
                            transform: scale(1.2);
                        }
                    }

                    &__price {
                        color: @red;
                    }
                }
            }
        }
    }   
}

@media(max-width: 1140px) {
    .catalog {
        margin: 0 -12px -24px;

        &__img {
            height: 19.3vw;
            margin-bottom: 6px;
        }

        &__price {
            font-size: 14px;
            line-height: 153%;
        }

        &__item {
            margin: 0 12px 24px;
            width: calc(~"50% - 24px");
        }

        &__name {
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 0;
        }
    }
}

@media(max-width: 960px) {
    .catalog {
        &__img {
            height: 29vw;
            margin-bottom: 12px;
        }

        &__name {
            margin-bottom: 8px;
        }
    }
}

@media(max-width: 640px) {
    .catalog {
        &__item {
            width: calc(~"100% - 24px");
        }

        &__img {
            height: 54vw;
        }
    }
}

@media(max-width: 480px) {
    .catalog {
        &__img {
            height: 57vw;
        }

        &__name {
            margin-bottom: 4px;
        }
    }
}