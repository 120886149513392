.mobile-menu {
    display: none;
}

@media(max-width: 1141px) {
    .mobile-menu {
        justify-content: flex-end;
        display: flex;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 55555;
        display: flex;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }
    
        &__bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 40px;
            flex: none;
        }
    
        .social {
            &__item {
                width: 40px;
                height: 40px; 
                margin-right: 12px;
                background: #810D10;
            }
        }
    
        .phone {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #0C0C0C;
            display: inline-flex;
            margin-bottom: 20px;
        }
    
        .lang {
            margin: 0;
            margin-bottom: 20px;
        }
    
        .menu-top {
            &>ul {
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
    
                &>li {
                    margin-bottom: 20px;
                    margin-right: 0;
    
                    &:last-child {
                        margin-bottom: 0;
                    }
                    
                    &>a {
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 18px;
                        letter-spacing: -0.02em;
                        color: @red;
                    }
                    
                }
            }
        }
    
        .user {
            display: block;
            margin-bottom: 26px;

            &__container {
                width: auto;
                padding: 0;
                box-shadow: none;
            }

            &__menu {
                display: none;
            }

            &__line {
                display: inline-flex;
                align-items: center;
                transform: translateY(-4px);
            }
    
            &__order {
                margin-bottom: 0;
                padding-bottom: 23px;
                border-bottom: 1px solid #E6E6E6;
            }

            &__wrap {
                opacity: 1;
                top: auto;
                padding-top: 16px;
                position: relative;
            }
    
            &__avatar {
                display: none;
            }
    
            &__count {
                position: relative;
                top: initial;
                right: initial;
            }
    
            &__main {
                font-size: 16px;
                line-height: 19px;
    
    
                span {
                    display: inline-flex;
                }
            }
        }
    
        &__body {
            padding: 0 24px;
            padding-bottom: 45px;
        }
    
        .menu {
            display: flex;
            position: relative;
            left: auto;
            top: auto;
            right: inherit;
            margin: 0;
            justify-content: flex-start;
            margin-bottom: 20px;
    
            &>ul {
                align-items: flex-start;
                flex-direction: column;
    
                &>li {
                    height: auto;
                    margin: 0 0 20px;
    
                    &>a {
                        font-size: 16px;
                        line-height: 19px;
                    }
    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    
        &__close {
            cursor: pointer;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        &__top {
            height: 48px;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            margin-bottom: 20px;
            font-family: 'Bitter', serif;
        }
    
        &__wrap {
            width: 360px;
            background-color: #fff;
            height: 100%;
            position: relative;
            z-index: 2;
            overflow-y: auto;
        }
    
        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%);
            opacity: 0.85;
        }
    }
}
