.total {
    display: flex;
    align-items: flex-start;

    &__body {
        width: 463px;
        flex: none;
        margin-right: 55px;

        .btn {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px; 
            margin-bottom: 16px;

            span {
                margin-right: 5px;
            }
        }

        p {
            color: @red;
        }
    }

    &__info {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;

        span {
            display: block;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;  
            letter-spacing: 0.05em;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .total {
        &__body {
            width: 32vw;
            flex: none;
            margin-right: 45px;
        }

        &__info {
            font-size: 2.2vw;

            span {
                font-size: 1.1vw;
            }
        }
    }
}

@media(max-width: 1140px) {
    .total {
        &__body {
            width: 420px;
            margin-right: 48px;
        }

        &__info {
            font-size: 22px;
            line-height: 100%;

            span {
                font-size: 14px;
                line-height: 100%;
                margin-bottom: 12px;
            }
        }
    }
}

@media(max-width: 960px) {
    .total {
        flex-direction: column;

        &__body {
            order: 2;
            width: 100%;
        }

        &__info {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            order: 1;
            margin-bottom: 24px;
            width: 100%;

            span {
                margin-bottom: -4px;
                margin-right: 12px;
            }
        }
    }
}

@media(max-width: 640px) {
    .total {
        &__body {
            .btn {
                span {
                    display: none;
                }
            }
        }
    }
}