.service {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 77px -36px -80px;

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(~"50% - 72px");
        margin: 0 36px 80px;
        color: @dark;
    }

    &__img {
        width: 100%;
        height: 210px;
        margin-bottom: 21px;

        &-wrap {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
        }
    }

    &__text {
        font-size: 16px;
        line-height: 153%;
        letter-spacing: -0.02em;
    }

    &__name {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        font-family: 'Bitter', serif;
        margin-bottom: 9px;
        transition: color 0.3s ease;
    }
}

@media(min-width: 960px) {
    .service {
        &__item {
            &:hover {
                .service {
                    &__name {
                        color: @red;
                    }
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .service {
        margin-top: 0;
        margin-left: -2vw;
        margin-right: -2vw;

        &__item {
            width: calc(~"50% - 4vw");
            margin: 0 2vw 4vw;
        }

        &__img {
            height: 15vw;
            margin-bottom: 1.5vw;
        }

        &__text {
            font-size: 1vw;
            line-height: 1.5;
        }
    
        &__name {
            font-size: 1.55vw;
            line-height: 1.2;
        }
    }
}

@media(max-width: 1140px) {
    .service {
        margin: 77px -16px -32px;

        &__img {
            height: 22vw;
            margin-bottom: 16px;
        }

        &__item {
            width: calc(~"50% - 32px");
            margin: 0 16px 32px;
        }

        &__text {
            font-size: 14px;
            line-height: 153%;
        }

        &__name {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 6px;
        }
    }
}


@media(max-width: 960px) {
    .service {
        margin: 77px -16px -32px;

        &__img {
            height: 42vw;
        }

        &__item {
            width: calc(~"100% - 32px");
        }
    }
}

@media(max-width: 640px) {
    .service {
        &__img {
            height: 43vw;
        }
    }
}

@media(max-width: 480px) {
    .service {
        &__img {
            height: 57vw;
        }
    }
}