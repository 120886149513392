.hamburger {
    display: none;
}


@media(max-width: 1140px) {
    .hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 28px;
        width: 72px;
        height: 100%;
        background-color: @red;
    }
}


@media(max-width: 960px) {
    .hamburger {
        width: 64px;
        margin-left: 27px;
    }
}

@media(max-width: 640px) {
    .hamburger {
        width: 56px;
        margin-left: 21px;
    }
}

@media(max-width: 480px) {
    .hamburger {
        width: 48px;
        margin-left: 18px;
    }
}