.filter-top {
    &__btn {
        display: none;

    }
}

@media(max-width: 960px) {
    .filter-top {
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;

        &__select {
            display: flex;
            flex-grow: 1;
        }

        &__btn {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 14px;
            flex: none;
            background-color: #303030;
        }
    }
}