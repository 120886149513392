.select {
    height: 64px;
    opacity: 0;
    transition: opacity 0.3s ease;
    width: 100%;

    &.jq-selectbox {
        width: 100%;
        opacity: 1;

        &.dropdown {
            .jq-selectbox__trigger {
                transform: rotate(180deg);
            }

            .jq-selectbox__select {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    .jq-selectbox__trigger {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
        top: 0;
        right: 0;
        transition: transform 0.3s ease;
        background-image: url(../images/common/arrow-select.png);
        background-position: center;
        background-repeat: no-repeat;
    }

    .jq-selectbox__dropdown {
        background: #FFFFFF;
        box-shadow: 0px 3px 7px rgba(51, 51, 51, 0.15);
        width: 100%;


        ul {
            padding: 16px 0;
            margin: 0;
            max-height: 368px !important;


            li {
                cursor: pointer;
                padding: 11px 16px 10px 30px;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                transition: color 0.3s ease;

                &.selected {
                    color: @red;
                }
            }
        }
    }

    .jq-selectbox__select-text {
        width: 100%;
        height: 100%;
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        z-index: 2;
        position: relative;
    }

    .jq-selectbox__select {
        cursor: pointer;
        background: #fff;
        height: 64px;
        width: 100%;
        position: relative;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(5.39deg, #FAFAFA 4.35%, #FFFFFF 95.75%);
            opacity: 0;
            transition: opacity 0.3s ease;
        }
    }

    &--transparent {
        &.jq-selectbox {
            &.dropdown {
                .jq-selectbox__select {
                    &::before {
                        opacity: 0;
                    }
                }
            }
        }

        .jq-selectbox__trigger {
            background-image: url(../images/common/arrow-select-white.png);
        }

        .jq-selectbox__select {
            background: rgba(255, 255, 255, 0.16);
        }

        .jq-selectbox__select-text {
            color: #fff;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .select {
        height: 4.5vw;

        .jq-selectbox__select {
            height: 4.5vw;
        }

        .jq-selectbox__dropdown ul li {
            line-height: 1.2;
            font-size: 1.55vw;
        }

        .jq-selectbox__trigger {
            height: 4.5vw;
            width: 4.5vw;
        }

        .jq-selectbox__select-text {
            font-size: 1.55vw;
        }
    }
}

@media(min-width: 960px) {
    .select {
        .jq-selectbox{
            &__dropdown {
                ul {
                    li {
                        &:hover {
                            color: @red;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .select {
        height: 46px;
        
        .jq-selectbox__select-text {
            font-size: 18px;
            line-height: 22px;
        }

        .jq-selectbox__trigger {
            width: 46px;
            height: 46px;
        }

        .jq-selectbox__select {
            height: 46px;
        }
    }
}