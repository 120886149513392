.card {
    display: flex;
    padding-bottom: 128px;

    &__slider {
        width: 100%;
       
        .slick-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 56px;
            height: 56px;
            position: absolute;
            border: 0;
            bottom: 0;
            z-index: 2;
            background-color: rgba(12, 12, 12, 0.5);
    
    
            .svg-icon {
                use {
                    fill: transparent;
                    stroke: #fff;
                }
            }
    
            &.slick-next {
                right: 0;
                padding-right: 2px;
            }
    
            &.slick-prev {
                right: 58px;
                padding-left: 2px;
                
            }
        }

        &-item {
            display: block;
            width: 100%;
            height: 456px;
            background-position: center;
            background-size: cover;
        }
    }

    &__main {
        width: 572px;  
    }

    &__top {
        .tech {
            display: none;
        }
    }

    &__info {
        flex-grow: 1;
        padding-left: 72px;

        .btn {
            width: 100%;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .card {
        padding-bottom: 67px;
        
        &__main {
            width: 37.5vw;
            flex: none;
        }

        &__info {
            padding-left: 52px;
        }

        &__slider {
            .slick-arrow {
                width: 43px;
                height: 43px;

                &.slick-prev {
                    right: 44px;
                }
            }

            &-item {
                height: 30vw;
            }
        }
    }
}

@media(max-width: 1140px) {
    .card {
        margin-top: -39px;

        &__slider {
            .slick-arrow {
                width: 48px;
                height: 48px; 

                &.slick-prev {
                    right: 49px;
                }
            }
        }

        &__main {
            padding-top: 28px;
            width: 428px;

            h1 {
                margin-bottom: 60px;
            }
            
        }

        &__info {
            padding-left: 24px;
        }

        &__slider {
            &-item {
                height: 290px;
            }
        }
    }
}

@media(max-width: 960px) {
    .card {
        display: block;

        .btn--upload {
            margin: 0 -40px 0;
            width: calc(~"100% + 80px");
            margin-bottom: 40px;

        }

        &__main {
            width: 100%;
        }
        
        &__info {
            padding-left: 0;

            .tech {
                display: none;
            }
        }

        &__slider {
            margin: 0 -40px 0;
            width: calc(~"100% + 80px");
            margin-bottom: 0;

            &-item {
                height: 62vw;
            }
        }

        &__top {
            display: flex;
            align-items: center;
            margin-bottom: 24px;

            h1 {
                margin-bottom: 0 !important;
            }

            .tech {
                width: 312px;
                margin-left: 24px;
                margin-bottom: 0;
                flex: none;
                display: block;
            }
        }
    }
}

@media(max-width: 640px) { 
    .card {
        padding-bottom: 51px;

        .btn--upload {
            margin: 0 -32px 0;
            width: calc(~"100% + 64px");
            margin-bottom: 40px;

        }

        &__slider {
            margin: 0 -32px 0;
            width: calc(~"100% + 64px");

            &-item {
                height: 52vw;
            }
        }

        &__info {
            .btn {
                width: calc(~"100% + 64px");
                margin-left: -32px;
                margin-right: -32px;
            }

            .tab-info {
                margin-top: 32px;
                margin-left: -32px;
                margin-right: -32px;
            }
        }

        &__top {
            display: block;
            margin-bottom: 28px;

            .tech {
                margin-left: 0;
                margin-top: 24px;
            }
        }
    }
}

@media(max-width: 480px) { 
    .card {
        margin-top: -21px;

        .btn--upload {
            margin: 0 -24px 0;
            width: calc(~"100% + 48px");
            margin-bottom: 40px;

        }

        &__slider {
            margin: 0 -24px 0;
            width: calc(~"100% + 48px");

            &-item {
                height: 69vw;
            }
        }
    }
}