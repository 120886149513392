.form-ok {
    position: relative;
    padding-top: 43px;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
    width: 478px;
    background: #FFFFFF;
    box-shadow: 0px 3px 7px rgba(51, 51, 51, 0.15);

    &__close {
        right: 24px;
        top: 24px;
        position: absolute;
        cursor: pointer;
        position: absolute;

        .svg-icon use {
            fill: #b5b5b5;
        }
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        z-index: 9090;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        text-align: center;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &-inner {
            background-color: rgba(31,47,88,.7);
        }

        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }
    
    }

    

    &__icon {
        flex: none;
        margin-bottom: 12px;
    }


    &__title {
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        color: #0C0C0C;
        margin-bottom: 5px;
    }

    &__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 153%;
        color: #0C0C0C;
        letter-spacing: -0.02em;
        
    }
}