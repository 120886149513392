.filter {
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 56px;
    padding-left: 60px;

    &__top {
        display: none;
    }
    
    &.wrapper {
        &__aside {
            width: 26%;
        }
    }

    &__wrap {
        display: flex;
    }

    &__btn {
        .btn {
            width: 100%;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__section {
        margin-bottom: 52px;
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 153%; 
        letter-spacing: -0.01em;
        margin-bottom: 23px;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        margin-right: 45px;
        font-weight: bold;
        flex: none;
        font-size: 12px;
        line-height: 150%;
        color: @dark;
        padding-bottom: 9px;
        letter-spacing: 0.13em;
        text-transform: uppercase;
        border-bottom: 4px solid @grey-light;
        transition: border-color 0.3s ease;

        &:last-child {
            margin-right: 0;
        }

        &.is-active {
            border-bottom-color: @green;
        }

        &:hover {
            border-bottom-color: @green;
        }
    }
}

@media(max-width: 1140px) { 
    .filter {
        &.wrapper__aside {
            &.col-25 {
                width: 241px;
                padding: 0;
            }
        }
    }
}

@media(max-width: 960px) {
    .filter {
        position: fixed;
        display: flex;
        justify-content: flex-end;
        opacity: 0;
        pointer-events: none;
        margin-bottom: 0;
        z-index: 50;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto !important;
        transition: opacity 0.3s ease;

        &::before {
            content:" ";
            position: absolute;
            left: 0px;
            top: 0;
            bottom: 0;
            width: calc(~"100% - 360px");
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%);
            opacity: 0.85;
            transform: rotate(180deg);
        }

        &__reset {
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            border: 0;
            padding: 0;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            font-family: 'Bitter', serif;
            color: #B5B5B5;  
        }
        
        &__close {
            display: inline-flex;
            align-items: center;
            font-weight: 600;
            font-size: 12px;
            line-height: 100%;
            letter-spacing: 0.12em;
            text-transform: uppercase;
            font-family: 'Bitter', serif;

            &-icon {
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &__top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-right: 21px;
            margin-bottom: 41px;
            flex: none;
        }

        &__wrap {
            width: 360px;
            background-color: #F2F2F2;
            height: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
        }
        
        &__title {
            margin-bottom: 20px;
        }

        &__section {
            margin-bottom: 48px;
        }
        

        &__body {
            padding: 0 25px 72px;
        }

        &.is-open {
            opacity: 1;
            pointer-events: auto;
        }

        &__item {
            font-size: 10px;
            line-height: 150%;  
            margin-right: 16px;
        }
    }
}

@media(max-width: 640px) {
    .filter {
        margin-right: -24px;
        overflow-x: auto;

        &__wrap {
            display: flex;
           flex: none;
        }

        &__item {

            &:last-child {
                margin-right: 24px;
            }
        }
    }
}