.contact-main {
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;

    &__icon {
        margin-left: 14px;

        .svg-icon {
            use {
                fill: #E0E0E0;
            }
        }
    }

    &__item {
        display: inline-flex;
        align-items: center;
        font-weight: 300;
        font-size: 17px;
        line-height: 160%;
        margin-bottom: 21px;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            display: inline-flex;
            align-items: center;
            color: #000;
            text-decoration: none;
            transition: color 0.3s ease;
        }
    }

    &__marker {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        flex: none;
        margin-right: 24px;

        .svg-icon {
            use {
                fill: #000;
            }
        }
    }
}

.contact-btn {
    margin-bottom: 56px;
}

@media(max-width: 960px) {
    .contact-main {
        margin-bottom: 43px;

        &__item {
            font-size: 14px;
            margin-bottom: 26px;
        }

        &__marker {
            margin-right: 16px;
        }
    }

    .contact-btn {
        margin-bottom: 36px;
    }
}

@media(min-width: 960px) {
    .contact-main {
        a {
            &:hover {
                color: #1A5DD1;

                .svg-icon {
                    use {
                        fill: #1A5DD1;
                    }
                }
            }
        }
    }
}
