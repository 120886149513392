.form-big {
    padding-top: 121px;
    padding-bottom: 148px;
    font-family: 'Bitter', serif;
    background-color: @bg;

    .container {
        position: relative;
    }

    &__container {
        display: flex;
        align-items: flex-start;
        margin: 0 -16px;
    }

    &__btn {
        margin-top: 32px;
        
        .btn {
            width: 100%;
        }
    }

    &__subtitle {
        text-align: left;
        font-size: 18px;
        line-height: 150%;  
        margin-bottom: 13px;
    }

    &__input {
        width: 100%;
        outline: 0;
        border: 0;
        padding: 0 20px;
        height: 56px;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: -0.02em;
        line-height: 100%;
        background-color: #fff;
    }

    &__col {
        width: 33.3%;
        padding: 0 16px;
    }

    &__title {
        font-weight: 600;
        font-size: 57px;
        line-height: 68px;
        text-align: center;
        margin-bottom: 16px;
    }

    &__text {
        font-size: 18px;
        line-height: 150%;
        text-align: center;
        max-width: 65%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 76px;
    }

    &--big {
        padding-top: 165px;
        padding-bottom: 170px;

        .form-big { 
            &__text {
                margin-bottom: 95px;
            }
        }
    }

    &--img {
        background-color: @dark;
        position: relative;
        background-position: center;
        background-size: cover;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0.65) 100%);
        }

        .form-big {
            &__title {
                color: #fff;
            }

            &__subtitle {
                color: #fff;
            }

            &__text {
                color: #fff;
            }
        }
    }

    &--banner {
        padding-top: 144px;

        &::before {
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0.65) 100%);
        }

        .container {
            width: 830px;
        }

        .form-big {
            &__title {
                margin-bottom: 81px;
            }

            &__btn {
                margin-top: 24px;
            }

            &__col {
                width: 50%;
                padding: 0 16px;
            }
        }
    }
}


@media (min-width: 1141px) and (max-width: 1440px) {
    .form-big {
        padding-top: 7.6vw;

        &__title {
            font-size: 4vw;
            margin-bottom: 0.5vw;
            line-height: 1.2;
        }

        &__subtitle {
            font-size: 1.3vw;
        }

        &__text {
            font-size: 1.3vw;
            margin-bottom: 5.1vw;
        }

        &--banner {
            padding-top: 9.8vw;
            padding-bottom: 10vw;

            .btn {
                padding: 18px 19px;
                font-size: 1.2vw;
            }

            .form-big {
                &__title {
                    margin-bottom: 5.5vw;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .form-big {
        padding-top: 70px;
        padding-bottom: 90px;

        &__container {
            
            margin: 0 -8px;
        }

        &__col {
            padding: 0 8px;
        }

        .container {
            padding: 0;
            max-width: 727px;
        }

        &__subtitle {
            font-size: 13px;
            line-height: 150%;
            margin-bottom: 4px;
        }

        &__title {
            font-size: 26px;
            line-height: 31px;  
            margin-bottom: 16px;
        }

        &__text {
            max-width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.01em;
            margin-bottom: 40px;
        }

        &--banner {
            padding-top: 80px;
            padding-bottom: 80px;

            .container {
                padding: 0;
                max-width: 577px;
            }

            .form-big {
                &__btn {
                    margin-top: 32px;
                }

                &__col {
                    padding: 0 8px;
                }

                &__title {
                    margin-bottom: 40px;
                    font-size: 36px;
                    line-height: 43px;
                }
            }
        }

        &--img {
            padding-top: 89px;
            padding-bottom: 90px;

            .form-big {
                &__text {
                    margin-bottom: 41px;
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .form-big {
        padding-top: 50px;
        padding-bottom: 67px;

        &__container {
            margin: 0;
            display: block;
        }

        &__col {
            width: 100%;
            padding: 0;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .container {
            padding: 0 40px;
        }

        &__subtitle {
            font-size: 13px;
            line-height: 150%;
            margin-bottom: 4px;
        }

        &__title {
            font-size: 26px;
            line-height: 31px;  
            margin-bottom: 16px;
        }

        &__text {
            max-width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.01em;
            margin-bottom: 40px;
        }

        &--banner {
            padding-top: 56px;
            padding-bottom: 100px;
            

            .container {
                max-width: 100%;
            }

            .form-big {
                &__container {
                    display: flex;
                }

                &__btn {
                    margin-top: 16px;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .form-big {
        padding-top: 52px;
        padding-bottom: 90px;
 
        .container {
            padding: 0 32px;
        }

        &--banner {
            .form-big {
                &__container {
                    display: block;
                }

                &__btn {
                    margin-top: 32px;
                }

                &__col {
                    padding: 0;
                    width: 100%;
                }
            }
        }
    }
}

@media(max-width: 480px) {
    .form-big {
        padding-bottom: 66px;

        .container {
            padding: 0 24px;
        }
    }
}