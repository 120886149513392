.menu {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 3;

    &>ul {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;

        &>li {
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0px 18px;
            height: 104px;

            &>a {
                color: @red;
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                display: flex;
                align-items: center;
             

                &.is-active {
                    color: @grey;

                    .svg-icon {
                        use {
                            fill: @grey;
                        }
                    }
                }

                &>.svg-icon {
                    opacity: .5;
                    margin-left: 3px;
                    margin-top: 13px;

                    use {
                        fill: @dark;
                    }
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .menu {
        &>ul {
            &>li {
                &>a {
                    font-size: 1.45vw;
                }
            }
        }
    }
}

@media(min-width: 960px) { 
    .menu {
        &>ul {
            &>li {
                &:hover {
                    .dropmenu {
                        opacity: 1;
                        pointer-events: auto;
                    } 
                }
            }
        }
    }
}

@media(max-width: 1140px) { 
    .menu {
        display: none;
    }
}