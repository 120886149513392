.tooltip {
    margin-left: 8px;
    margin-bottom: -1px;
    position: relative;

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        background-color: #E0E0E0;
        transition: background-color 0.3s ease;
    }

    &__body {
        background-color: #000;
        font-size: 14px;
        line-height: 150%;
        color: #000;
        width: 276px;
        padding: 8px 12px;
        color: #fff;
        position: absolute;
        left: 0;
        bottom: 20px;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &.is-active {
        .tooltip {
            &__wrap {
                background-color: #000;
            }

            &__body {
                opacity: 1;
            }
        }
    }
}


.tooltip {
    &:hover {
        .tooltip {
            &__wrap {
                background-color: #000;
            }

            &__body {
                opacity: 1;
            }
        }
    }
}


@media (min-width: 1141px) and (max-width: 1440px) {
    .tooltip {
        margin-left: 5px;

        &__body {
            bottom: 1.3vw;
            font-size: 1vw;
            max-width: 214px;
        }

        &__wrap {
            width: 1.3vw;
            height: 1.3vw;
            background-color: #000;

            svg {
                width: 0.4vw;
            }
        }

    }
}

@media (max-width: 961px) {
    .tooltip {
        &__wrap {
            width: 16px;
            height: 16px;

            svg {
                width: 5px;
                height: auto;
            }
        }

        &__body {
            bottom: 16px;
            right: 0;
            font-size: 10px;
            width: 165px;
            left: inherit;
        }
    }
}