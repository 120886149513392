.user {
    display: inline-flex;
    align-items: center;
    position: relative;

    &__main {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: @red;

        &~span {
            font-size: 20px;
            line-height: 24px;
            color: @red;
            margin: 0 3px;
        }
    }

    &__avatar {
        display: none;
    }

    &__count {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: @red;
        border-radius: 50%;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-left: 10px;
        color: #FFFFFF;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            display: inline-flex;
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 16px;
            color: inherit;
            letter-spacing: -0.02em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__order {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        &-body {
            font-size: 12px;
            line-height: 168%;
            letter-spacing: -0.02em;

            span {
                display: block;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                font-family: 'Bitter', serif;
            }
        }
    }

    &__btn {
        flex: none;
        margin-left: 12px;
    }

    &__wrap {
        top: 100%;
        right: 0;
        position: absolute;
        padding-top: 36px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;
    }

    &__container {
        width: 328px;
        padding: 38px 32px 36px;
        background: #FFFFFF;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    }
}

@media(min-width: 960px) {
    .user {
        &:hover {
            .user {
                &__wrap {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        &__menu {
            a {
                &:hover {
                    color: @red;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .user {
        &__main {
            position: relative;

            span {
                display: none;
            }
        }

        &__line {
            display: none;
        }

        &__count {
            position: absolute;
            top: -6px;
            right: 0;
            width: 21px;
            height: 21px;
            font-weight: normal;
            font-size: 11px;
            line-height: 100%;  
        }

        &__avatar {
            display: block;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background-position: center;
            background-size: cover;
        }
    }
}

@media(max-width: 960px) {
    .user {
        &__avatar {
            width: 43px;
            height: 43px;
        }

        &__count {
            width: 19px;
            height: 19px;
        }
    }
}


@media(max-width: 640px) {
    .user {
        &__avatar {
            width: 37px;
            height: 37px;
        }

        &__count {
            width: 16px;
            height: 16px;
        }
    }
}

@media(max-width: 480px) {
    .user {
        &__avatar {
            width: 32px;
            height: 32px;
        }

        &__count {
            width: 13px;
            height: 13px;
        }
    }
}