.req {
    width: 605px;
    margin: 0 auto;

    .form__field.is-active .form__input {
        background-color: #fff;
    }

    .agree {
        position: relative;
        margin-right: 12px;

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
            z-index: 2;

            &:checked {
                &~.agree {
                    &__control {
                        background-color: #992B2E;

                        svg {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        &__control {
            width: 20px;
            height: 20px;
           
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 2px solid #992B2E;
            transition: background-color 0.3s ease;

            svg {
                opacity: 0;
                transition: opacity 0.3s ease;
            }
        }
    }

    .form {
        &__btn {
            margin-top: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .btn {
                order: 2;
            }
        }

        

        &__agree {
            order: 1;
            margin-top: 0;
            margin-bottom: 12px;
            font-weight: 400;
            font-size: 14px;
            line-height: 168%;
            color: #717171;
            letter-spacing: -0.02em;

            a {
                color: #810D10;
                text-decoration: underline;
            }
        }
    }

    &__tab {
        &-link {
            width: 50%;
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            padding-bottom: 12px;
            letter-spacing: 0.12em;
            text-align: center;
            text-transform: uppercase;
            color: #0C0C0C;
            font-family: 'Bitter', serif;
            border-bottom: 3px solid transparent;
            transition: border-color 0.3s ease;
    
            &.is-active {
                border-bottom: 3px solid  #810D10;
            }
        }
    }

    &__control {
        display: flex;
    }

    

    &__title {
        text-align: center;
        margin-bottom: 12px;

        h1 {
            margin: 0;
        }
    }

    &__body {
        display: none;

        &.is-active {
            display: block;
        }

        &-wrap {
            padding: 40px 48px 56px;
            background: #F2F2F2;
        }
    }

    &__text {
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        font-family: 'Fira Sans', sans-serif;
        line-height: 150%;
        letter-spacing: -0.02em; 
        margin-bottom: 40px;

        a {
            color: #992B2E;
        }
    }
}

@media(max-width: 768px) {
    .req {
        width: 100%;

        &__tab {
            &-link {
                font-size: 9px;
                line-height: 100%;
                padding-bottom: 8px;
            }
        }

        &__body {
            &-wrap {
                padding: 12px;
            }
        }
    }
}