/*Bender*/

@font-face{
    font-family: 'Bender';
    src: url("../fonts/Bender/Bender.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: normal; 
}

@font-face{
    font-family: 'Bender';
    src: url("../fonts/Bender/Bender-Bold.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: bold; 
}

@font-face{
    font-family: 'Bender';
    src: url("../fonts/Bender/Bender-Black.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 900; 
}

/*Rubik*/
@font-face{
    font-family: 'Rubik';
    src: url("../fonts/Rubik/Rubik-Regular.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: bold; 
}

@font-face{
    font-family: 'Rubik';
    src: url("../fonts/Rubik/Rubik-SemiBold.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 500; 
}

@font-face{
    font-family: 'Rubik';
    src: url("../fonts/Rubik/Rubik-Regular.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 400; 
}


/*Roboto*/
@font-face{
    font-family: 'Roboto';
    src: url("../fonts/Roboto/Roboto-Regular.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: bold; 
}