.slick-for {
    position: relative;

    &__item {
        width: 800px;
        height: 600px;
        display: flex;
        background-position: center;
        background-size: cover;
    }

    .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 56px;
        height: 56px;
        position: absolute;
        border: 0;
        bottom: 0;
        z-index: 2;
        background-color: rgba(12, 12, 12, 0.5);


        .svg-icon {
            use {
                fill: transparent;
                stroke: #fff;
            }
        }

        &.slick-next {
            right: 0;
            padding-right: 2px;
        }

        &.slick-prev {
            right: 58px;
            padding-left: 2px;
            
        }
    }
}

.slick-nav {
    margin-top: 2px;
    position: relative;
    margin-bottom: 39px;

    .slick-slide {
       
        padding-right: 2px;
        transition: opacity 0.3s ease;

        &.slick-current {
            opacity: 0.2;
        }
    }

    &__item {
        width: 112px;
        height: 85px;
        cursor: pointer;
        display: flex;
        background-position: center;
        background-size: cover;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .slick-for {
        &__item {
            width: 100%;
            height: 42vw;
        }

        .slick-arrow {
            width: 4vw;
            height: 4vw;

            &.slick-prev {
                right: calc(~"4vw + 2px");
            }
        }
    }

    .slick-nav {
        margin-bottom: 2.7vw;
        
        &__item {
            width: 7.8vw;
            height: 5.5vw;
        }
    }
}

@media(max-width: 1140px) { 
    .slick-for {
        &__item {
            width: 100%;
            height: 45vw;
        }

        .slick-arrow {
            width: 48px;
            height: 48px;

            &.slick-prev {
                right: 49px;
            }
        }
    }

    .slick-nav {
        margin-bottom: 32px;

        .slick-slide {
            padding-right: 1px;
        }

        &__item {
            width: 66.54px;
            height: 50.28px;  
        }
    }
}

@media(max-width: 960px) {
    .slick-for {
        margin-right: -40px;
        margin-left: -40px;
        margin-bottom: 32px;

        &__item {
            width: 100%;
            height: 75vw;
        }
    }

    .slick-nav {
        display: none;
    }
}

@media(max-width: 640px) {
    .slick-for {
        margin-right: -32px;
        margin-left: -32px;
        margin-bottom: 40px;

        &__item {
            height: 73vw;
        }
    }
}

@media(max-width: 480px) {
    .slick-for {
        margin-right: -24px;
        margin-left: -24px;
        margin-bottom: 32px;

        &__item {
            height: 75vw;
        }
    }
}