.question {
    &__name {
        font-weight: 600;
        font-size: 22px;
        line-height: 120%;
        margin-bottom: 9px;
    }

    &__item {
        margin-bottom: 48px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__text {
        font-weight: 300;
        font-size: 17px;
        line-height: 160%;
    }

    &__btn {
        margin-top: 48px;
        
        .btn {
            width: 100%;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .question {
        &__name {
            font-size: 1.58vw;
            margin-bottom: 7px;
        }

        &__item {
            margin-bottom: 34px;
        }

        &__text {
            font-size: 1.22vw;
        }
    }
}

@media (max-width: 960px)  {
    .question {
        &__name {
            font-size: 17px;
            margin-bottom: 9px;
        }

        &__text {
            font-size: 14px;
        }

        &__item {
            margin-bottom: 24px;
        }
    }
}