.lang {
    display: inline-flex;
    align-items: center;
    margin-left: 46px;

    span {
        display: inline-flex;
        margin: 0 5px;
        width: 1px;
        height: 20px;
        background-color: @grey1;
    }

    a {
        font-size: 17px;
        line-height: 20px;
        letter-spacing: -0.02em; 
        color: @grey1;

        &.is-active {
            color: @red;
        }
    }
}

@media(max-width: 960px) {
    .lang {
        a {
            font-size: 15px;
            line-height: 18px;
        }

        span {
            height: 18px;
        }
    }
}