.main-tech {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__step {
        font-size: 13px;
        line-height: 150%;
        color: @grey;
        letter-spacing: 0.07em;
        font-weight: normal;
        margin-bottom: 16px;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
    }

    &__wrap {
        position: fixed;
        transform: translateY(100vh);
        opacity: 0;
        background-color: #fff;
        transition: transform 1.2s, opacity 0.5s linear;
        width: 100%;
        height: 100vh;
        z-index: 2;

        &.is-active {
            opacity: 1;
            transform: translateY(0);
        }

        &.is-top {
            transform: translateY(-100vh);
        }
    }

    &__tech {
        display: flex;
        align-items: center;

        
    }

    &__btn {
        display: flex;
        align-items: center;

        margin-top: 64px;

        .btn {
            margin-right: 20px;
        }
    }

    &__info {
        div {
            font-weight: bold;
            font-size: 17px;
            line-height: 132%;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            span {
                width: 87px;
                font-size: 29px;
                font-weight: normal;
                line-height: 33px;
                flex: none;
                margin-right: 5px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__schema {
        width: 765px;
        height: 603px;
        flex: none;
        position: relative;
        

        .schema {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.3s ease;

            &.is-active {
                pointer-events: auto;
                opacity: 1;
            }

            &__img {
                height: 603px;
            }

            &__container {
                height: 603px;
            }

            &.is-active {
                display: block;
            }
        }
    }

    &__name {
        font-weight: bold;
        font-size: 30px;
        line-height: 132%;
        max-width: 89%;
        margin-bottom: 40px;
    }

    &__title {

        position: fixed;
        right: 0;
        left: 0;
        top: 149px;
        z-index: 500;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s ease;

        &.is-active {
            pointer-events: auto;
            opacity: 1;
        }

        .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
       
        &-body {
            display: flex;
            align-items: center;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.13em;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    &__control {
        display: flex;
      


        &-item {
            display: inline-flex;
            align-items: flex-start;
            cursor: pointer;
            font-size: 13px;
            line-height: 150%;
            letter-spacing: 0.07em;
            margin-left: 50px;
            color: @grey;
            transition: color 0.3s ease;
            margin-bottom: 18px;

            &:last-child {
                margin-bottom: 0;
            }

            &.is-active {
                color: @green;
            }
        }
    }
}

@media(max-width: 960px) {
    .main-tech {
        &__wrap {
            position: relative;
            transform: translateY(0);
        }

        &__name {
            font-size: 20px;
            line-height: 132%;
            margin-bottom: 16px;
        }

        &__info {
            div {
                font-size: 14px;
                line-height: 150%;
                margin-bottom: 10px;

                span {
                    width: 56px;
                    font-size: 22px;
                    line-height: 25px;
                }
            }

            
        }
    }
}