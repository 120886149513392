.service-item {
    display: flex;
    flex-direction: column;
    padding: 14px 0 24px 0 ;
    border-bottom: 1px solid #000;
    text-decoration: none;
    color: #000;
    position: relative;

    &:last-child {
        padding-bottom: 0;
        border: 0;
    }

    &__section {
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.1em;
        font-family: 'Galderglynn1884Cd';
        color: #ABABAB;
        margin-bottom: 6px;
    }

    &__icon-link {
        position: absolute;
        top: 33px;
        right: 0;

        .svg-icon {
            width: 20px;
            height: auto;

            use {
                fill: #E0E0E0;
            }
        }

        &:hover {
            .svg-icon {
                use {
                    fill: #fff;
                }
            }
        }
    }

    &__link {
        font-size: 14px;
        line-height: 150%;
        display: inline-flex;
        letter-spacing: 0.1em;
        color: #E0E0E0;
        font-family: 'Galderglynn1884Cd';
        text-decoration: none;
        transition: color 0.3s ease;
    }

    &__type {
        font-size: 14px;
        line-height: 150%;
        letter-spacing: .1em;
        display: inline-flex;
        align-items: center;

        font-family: 'Galderglynn1884Cd';
    }

    &__icon {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 9px;

        &--free {
            background-color:  #1A5DD1;
        }

        &--sof {
            background: linear-gradient(135deg, #1A5DD1 50%, #E40F18 50.01%);
        }
    }

    &__name {
        font-weight: 300;
        font-size: 17px;
        line-height: 160%;
        text-decoration: none;
        color: #000;
    }

    &__body {
        margin-top: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media(min-width: 960px) {
    .service-item {
        &:hover {
            .service-item {
                &__link {
                    color: #fff;
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .service-item {
        padding: 1.1vw 0 1.6vw;

        &__body {
            margin-top: 1vw;
        }

        &__name {
            font-size: 1.215vw;
        }

        &__link {
            font-size: 1vw;
        }

        &__type {
            font-size: 1vw;
        }

        &__icon {
            width: .9vw;
            height: .9vw;
            margin-right: 0.5vw;
        }
    }
}

@media (max-width: 960px) {
    .service-item {
        padding: 13px 0;

        &__name {
            font-size: 14px;
        }

        &__link {
            font-size: 12px;
            color: #fff
        }

        &__body {
            margin-top: 9px;
        }

        &__type {
            font-size: 12px;
        }

        &__icon {
            width: 12px;
            height: 12px;
            margin-right: 6px;
        }
    }
}