.main-block {
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 100px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    
    &__img {
        background-position: center;
        background-size: cover;
    }

    &__text {
        p {
            margin: 0 0 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__body {
        padding-right: 58px;
    }

    &__img {
        flex: none;
        width: 591px;
        height: 393px;
    }

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        font-family: 'Bitter', serif;
        margin-bottom: 32px;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .main-block {
        &__img {
            width: 41vw;
            height: 27vw;
        }

        &__item {
            margin-bottom: 5vw;
        }

        &__title {
            font-size: 1.6vw;
            margin-bottom: 2vw
        }
    }
}

@media(max-width: 960px) {
    .main-block {
        &__item {
            display: block;
            margin-bottom: 64px;

            p {
                font-size: 16px;
                line-height: 150%;
                margin-bottom: 16px;
            }
        }

        &__title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 24px;
        }

        &__img {
            width: 100%;
        }

        &__body {
            padding-right: 0;
            margin-bottom: 20px;
        }
    }
}


@media(max-width: 480px) {
    .main-block {
        &__item {
            margin-bottom: 56px;
        }
    }
}