.ldata {
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 32px; 
    }

    &__link {
        margin-top: 24px;

        a {
            font-weight: 400;
            font-size: 16px;
            line-height: 153%;
            letter-spacing: -0.02em;
            text-decoration: underline;
            color: #810D10;
        }
    }

    &__container {
        margin-bottom: 64px;
    }

    &__section {
        margin-bottom: 100px;

        &--small {
            margin-bottom: 48px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .ldata {
        padding-top: 9px;

        &__title {
            font-size: 1.65vw;
            margin-bottom: 21px;
        }

        &__section {
            margin-bottom: 77px;
            
            &--small {
                margin-bottom: 35px;
            }
        }
    }
}

@media(max-width: 1140px) { 
    .ldata {
        &__title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 24px;
        }

        &__section {
            margin-bottom: 60px;

        }
    }
}

@media(max-width: 960px) {
    .ldata {
        &__section {
            margin-bottom: 40px;

            &--small {
                margin-bottom: 32px;
            }
        }

        &__container {
            margin-bottom: 40px;
        }

        &__btn {
            .btn {
                width: 100%;
            }
        }
    }
}