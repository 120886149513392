.warning {
    display: inline-flex;
    align-items: flex-start;
    max-width: 488px;
    font-size: 16px;
    line-height: 153%;
    letter-spacing: -0.02em;
    color: @red;
    margin-bottom: 72px;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        flex: none;
        width: 26px;
        height: 26px;
        background-color: #EDE6E6;
        border-radius: 13px;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .warning {
        max-width: 78%;
        font-size: 1.1vw;
        margin-bottom: 5vw;

        &__icon {
            width: 2vw;
            height: 2vw;
            margin-right: 0.9vw;

            svg {
                width: 3px;
                height: auto;
            }
        }
    }
}

@media(max-width: 1140px) {
    .warning {
        font-size: 14px;
        line-height: 153%;
        margin-bottom: 36px;

        &__icon {
            margin-right: 7px;
        }
    }
}