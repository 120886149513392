.order-item {
    margin-top: 48px;
    margin-bottom: 72px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 12px;
        padding-top: 12px;
        border-bottom: 1px solid #F2F2F2;

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }

        &:first-child {
            padding-top: 0;
        }
    }

    &__info {
        font-size: 14px;
        line-height: 168%;
        letter-spacing: -0.02em;

        span {
            display: block;
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 19px;
        height: 18px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            .svg-icon {
                use {
                    stroke: #B5B5B5;
                    fill: transparent
                }
            }
        }
    }

    &__price {
        font-weight: bold;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: -0.01em;
    }

    &__main {
        display: flex;
        align-items: center;
    }

    &__img {
        width: 104px;
        margin-right: 19px;
        flex: none;
    }

    &__type {
        font-size: 14px;
        line-height: 110%;
        color: #717171;
    }

    &__name {
        display: inline-flex;
        font-weight: 500;
        font-size: 16px;
        line-height: 132%;
        color: #0C0C0C;
        margin-bottom: 8px;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .order-item {
        margin-bottom: 5vw;

        &__img {
            width: 7vw;
            margin-right: 1.5vw;
        }

        &__type {
            font-size: 1vw;
            line-height: 1.2;
        }

        &__btn {
            svg {
                width: 1.4vw;
                height: auto;
            }
        
        }

        &__info {
            font-size: 1vw;
        }

        &__price {
            font-size: 1.3vw;
        }

        &__name {
            font-size: 1.15vw;
            line-height: 1.2;
            margin-bottom: 3px;
        }
    }
}

@media(min-width: 960px) {
    .order-item {
        &__btn {
            &:hover {
                .svg-icon {
                    use {
                        stroke: @red;
                    }
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .order-item {
        margin-top: 35px;
        margin-bottom: 40px;

        &__name {
            font-size: 14px;
            line-height: 120%;
            margin-bottom: 6px;
        }

        &__btn {
            svg {
                width: 16px;
                height: auto;
            }
        }

        &__price {
            font-size: 16px;
            line-height: 150%;
        }

        &__body {
            width: 204px;
        }

        &__type {
            font-size: 12px;
            line-height: 110%;
        }

        &__img {
            width: 80px;
            margin-right: 12px;
        }
    }
}

@media(max-width: 640px) {
    .order-item {
        &__btn {
            width: 24px;
            height: 24px;
            position: absolute;
            right: 0;
            top: 19px;
        }

        &__price {
            font-size: 18px;
            line-height: 150%;
        }
        
        &__item {
            position: relative;
            flex-wrap: wrap;
        }
        
        &__main {
            width: 100%;
            padding-right: 40px;
            margin-bottom: 16px;
        }
    }
}