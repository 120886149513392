.banner {
    display: flex;
    margin: 0 -24px;

    &__item {
        margin: 0 24px;

        &:nth-child(1) {
            width: calc(~"67% - 48px");
        }

        &:nth-child(2) {
            width: calc(~"33% - 48px");
        }
    }
}