.chapter {
    margin-top: 48px;

    h4 {
        margin-bottom: 32px;
    }

    .btn {
        margin-top: 20px;
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px 16px;
    }

    &__name {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        position: relative;
        color: #fff;
        z-index: 2;
    }

    &__item {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        padding: 13px;
        height: 144px;
        width: calc(~"33.3% - 16px");
        margin: 0 8px 16px;
        position: relative;
        background-position: center;
        background-size: cover;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(360deg, rgba(0, 0, 0, 0.74) 0%, rgba(0, 0, 0, 0) 61.68%);
        }
    }
}


@media (min-width: 1141px) and (max-width: 1440px) {
    .chapter {
        margin-top: 31px; 

        &__name {
            font-size: 1.2vw;
        }

        &__item {
            height: 10vw;
        }

        h4 {
            margin-bottom: 1.5vw;
        }
    }
}

@media(max-width: 1140px) {
    .chapter {
        &__name {
            font-size: 16px;
            line-height: 150%;
            text-align: center;
        }

        &__item {
            height: 12vw;
            align-items: center;

            &::before {
                background: rgba(0, 0, 0, 0.3);
            }
        }
    }
}

@media(max-width: 960px) { 
    .chapter {
        margin-top: 32px;

        h4 {
            margin-bottom: 24px;
        }
    }
}

@media(max-width: 640px) { 
    .chapter {
        &__item {
            height: 15.5vw;
            width: calc(~"50% - 16px");
        }

        .btn {
            width: 100%;
        }
    }
}

@media(max-width: 480px) { 
    .chapter {
        &__item {
            height: 20.5vw;
            width: calc(~"50% - 16px");
        }

        .btn {
            width: 100%;
        }
    }
}