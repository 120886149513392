.question-section {
    display: flex;

    .h1 {
        margin-top: 0;
    }

    &__main {
        width: 820px;
        flex: none;
    }
}

@media (min-width: 1141px) and (max-width: 1440px) {
    .question-section {
        &__main {
            width: 65%;
        }

        &__body {
            width: 35%;
            flex: none;
        }
        
    }
}

@media (max-width: 960px)  {
    .question-section {
        display: block;

        &__main {
            width: 100%;
           
        }

        &__body {
            width: 100%;
            margin-bottom: 32px;
        }
    }
}